import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WebcamImage } from 'ngx-webcam';
import { BrodcastManagerService } from 'src/app/Service/brodcast-manager.service';
import { CollectionManagerService } from 'src/app/Service/collection-manager.service';
import { DataServiceService } from 'src/app/Service/data-service.service';

import { idText } from 'typescript';
import { PopupService } from '../../PopupMasters/popup.service';

@Component({
  selector: 'app-defects',
  templateUrl: './defects.component.html',
  styleUrls: ['./defects.component.scss']
})
export class DefectsComponent implements OnInit {
  @Input() modalData: any;
  @Input() modalId: any;
  webcamImage: WebcamImage = null;
  formData: any = new FormData(); //dupload files
  defectsForm!: FormGroup;//defect form group
  status: any = {};//storing status object
  group: any = {};//storing group object
  defectsId = '';//storing defects id
  statusList = [];
  groupList = [];
  broadcastSubs: any; // communication service instance
  statusName: any;
  groupName: any;
  colorchanges: any;
  groupColorchanges: any;
  typeCollection: any = '';//getting and storing typecollection from system nav panel
  uploadFiles: any = [];
  openCameraState: boolean = false;
  usersDetails = [
    {
      "_id": "610d3dbfd6b4251d4cc9b220",
      "email": "raj.t@datifex.com",
      "isChecked": false,
      "SCS": "Y",
      "SDMS": "Y",
      "MARTECH": "Y",
      "MARLANT": "Y",
      "MARPAC": "Y",
      "Last Name": "Thulasidoss",
      "First Name": "Raj",
      "Rank E": "Civ",
      "Rank F": "Civ",
      "Org E": "Datifex, Inc.",
      "Org F": "Datifex, Inc.",
      "Role": "CO",
      "Cell": "(902) 439-4509",
      "Location": null,
      "Language": "E",
      "Admin": "Y",
      "hazard": "Y",
      "aed": "Y",
      "first-aid": "Y",
      "spill-kits": "Y",
      "evacuation-posters": "Y",
      "kiosk": "Y",
      "laydown": "Y",
      "contractors": "Y",
      "portable-generators": "Y",
      "fork-lifts": "Y",
      "cranes": "Y",
      "FMF": "Y",
      "WFM": "Y",
      "teamsid": "raj.t@datifex.com",
      "SLMS": "Y",
      "idg": "Y",
      "inexxt_campus": "Y",
      "certPath": "Y",
      "roleRes": "Y",
      "know_ur_ship": "Y",
      "trouble": "Y",
      "assemble_part": "Y",
      "id_part": "Y",
      "quiz": "Y",
      "diesel_gen": "Y",
      "Buildingsbulid": "Y",
      "Halifaxbulid": "Y",
      "Engineulid": "Y",
      "rhib": "Y",
      "fleetway": "Y",
      "ourship": "Y",
      "yourship": "Y",
      "dvt": "Y",
      "radhaz": "Y",
      "Commsc": "Y",
      "commanding": "Y",
      "sdmslibrary": "Y",
      "Officer": "Y",
      "fleets": "Y",
      "nsc": "Y",
      "ern": "Y",
      "aops": "Y",
      "materiel": "Y",
      "compartments": "Y",
      "scslibrary": "Y",
      "stru": "Y",
      "buoyancy": "Y",
      "eng": "Y",
      "seam": "Y",
      "fire": "Y",
      "EER": "Y",
      "navalcomms": "Y",
      "navi": "Y",
      "dang": "Y",
      "good": "Y",
      "damage": "Y",
      "prop": "Y",
      "electrical": "Y",
      "marine": "Y",
      "deck": "Y",
      "hull": "Y",
      "hvac": "Y",
      "aopsdamage": "Y",
      "aopsprop": "Y",
      "aopselectrical": "Y",
      "aopsmarine": "Y",
      "aopsdeck": "Y",
      "aopshull": "Y",
      "aopshvac": "Y",
      "aopscoundselectrical": "Y",
      "aircraftsupport": "Y",
      "navis": "Y",
      "imps": "Y",
      "workshop": "Y",
      "communication": "Y",
      "navysys": "Y",
      "candc": "Y",
      "weapons": "Y",
      "domestic": "Y",
      "materielstru": "Y",
      "materielbuoyancy": "Y",
      "materieleng": "Y",
      "materielseam": "Y",
      "materielfire": "Y",
      "materielEER": "Y",
      "materielnavalcomms": null,
      "materielnavi": "Y",
      "materieldang": "Y",
      "materielgood": "Y",
      "power_gen": "Y",
      "bulid": "Y",
      "power_gen_rqs": "Y",
      "power_dis_rqs": "Y",
      "power_dis_ls": "Y",
      "role_bulid": "Y",
      "role_power_gen": "Y",
      "role_power_dis": "Y",
      "power_dis": "Y",
      "build_op": "Y",
      "main_role": "Y",
      "mast": "Y",
      "trouble_power_gen": "Y",
      "know_ur_ship_bulid": "Y",
      "know_ur_ship_compartment": "Y",
      "know_ur_ship_misc": "Y",
      "know_ur_ship_kys": "Y",
      "mepm": "Y",
      "Fmfcb": "Y",
      "msd": "Y",
      "node_dvt": "Y",
      "engine_model": "Y",
      "visitor_information": "Y",
      "visitor_registration": "Y",
      "visitor_scan": "Y",
      "kys_quiz": "Y",
      "slmsvideos": "Y",
      "woodroffe": null,
      "fluid_Systems": "N",
      "kys_halifax_class": "Y",
      "kys_halifax_class_ship": "Y",
      "kys_halifax_class_ship_seawater": "Y",
      "sos_save_our_ship": "Y",
      "Pin": "1234",
      "comp_ur_ship_kys": "N",
      "KHAs_kys": "N",
      "kys_sea": "N",
      "kys_exit": "N",
      "kys_course": "N",
      "demo_models": "Y",
      "Suspend": "N"
    },
  ]

  constructor(private broadcastServ: BrodcastManagerService, public formBuilder: FormBuilder, private dataServ: DataServiceService, private collectionServ: CollectionManagerService, private popupService: PopupService) { }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.broadcastServ.getInstance().next(data);
  }

  ngOnInit(): void {
    this.defectsFormInitialize();

    this.broadcastSubs = this.broadcastServ.getInstance()
      .subscribe((data: any) => {

        /**
         * event form sync-service to close popup
         */
        if (data.src === "sync-service") {
          if (data.event === 'close-defects-popup') {
            this.closeDefects();
          }
        }

      });
  }

  /* * *
  * method for defects form initialization
  * * */
  defectsFormInitialize() {
    //** Defects Form **/
    this.defectsForm = this.formBuilder.group({      
      description: '',
      startDate: [''],
      userList: [''],
      materialNo: [''],
      surveyNo: [''],
      pmrsNo: [''],
      componentId: [''],
    });

    this.typeCollection = this.modalData.collectionType;

    //** condition for new/edit defects popup **/
    if (this.modalData['notesData']) {
      this.defectsId = this.modalData['notesData'].typeCollectionId;
      this.setFormValues(this.modalData['notesData']);
      this.setNotesFormValues(this.modalData['notesData']);
      this.fetchDefectsData(this.modalData['notesData']['defectsData']);
    } else {
      this.clearDefectsForm();
    }

    //** get selectedCollection and store collection values **/
    let selectedCollection = this.collectionServ.getSelectedCollection();
    this.groupList = selectedCollection.groupCollection;
    this.statusList = selectedCollection.statusCollection;
  }

  /* * *
  * method for fetch status data
  * * */
  fetchStatus(statusId) {
    let selectedStatus = this.statusList.filter(status => status._id == statusId)[0];
    this.colorchanges = selectedStatus.color;
    this.status = {
      'value': this.colorchanges,
      "name": selectedStatus.name,
      'statusId': selectedStatus._id
    }
    this.statusName = selectedStatus.name;
  }

  /* * *
  * method for fetch group data
  * * */
  fetchGroup(groupId) {
    let selectedGroup = this.groupList.filter(group => group._id == groupId)[0];
    this.groupColorchanges = selectedGroup.color;
    this.group = {
      'value': this.groupColorchanges,
      "name": selectedGroup.name,
      'statusId': selectedGroup._id
    }
    this.groupName = selectedGroup.name;
  }

  /* * *
  * method for create/update defects data
  * * */
  saveDefects() {
    if (this.defectsId != "") {
      this.updateNotes();
    } else {
      this.createNotes();
    }
  }

  /* * *
  * method for construct post defects data
  * * */
  getDefectsData() {
    let pushParams = {
      "status": this.status,
      'group': this.group,
      "statusId": this.status.statusId,
      "uploadFile": this.uploadFiles,
      "venue":this.dataServ.projectName
    }
    let objPushData = Object.assign({}, pushParams, this.defectsForm.value);
    return objPushData;
  }

  /* * *
  * method for push defects api call 
  * * */
  pushDefectsData() {
    let defectsData = this.getDefectsData();
    defectsData['uploadFile'] = [];
    this.dataServ.createDefects(defectsData).then(response => {
      this.defectsId = response['insertedId'];
      this.createNotes();
    })
  }

  /* * *
  * method for construct notesData 
  * * */
  createNotes() {
    let pushParams = {
      defectsData: this.getDefectsData(),
      collectionId: this.defectsId,
      description: this.getDefectsData()['description'] || '',
      status: this.getDefectsData()['status'] || {},
      group: this.getDefectsData()['group'] || {},
      typeCollection: this.typeCollection,
      filterDate: new Date(),
      selectedCollection: this.collectionServ.getSelectedCollection(),
      uploadFile: this.uploadFiles,
      pmrsNo: this.getDefectsData()['pmrsNo'] || ''
    }
    this.collectionServ.manageCollectionNotes(pushParams, true);
  }

  /* * *
  * method for fetch defects data based on defectsId 
  * * */
  fetchDefectsData(defectsData = {}) {
    if (Object.keys(defectsData).length === 0) return;
    this.setFormValues(defectsData);
  }

  /* * *
  * method for set notes values
  * * */
  setNotesFormValues(formData) {
    this.defectsForm.controls['componentId'].setValue(formData.componentId || "");
  }

  /* * *
  * method for set values in form 
  * * */
  setFormValues(formData) {    
    this.defectsForm.controls['description'].setValue(formData.description);
    this.defectsForm.controls['startDate'].setValue(formData.startDate);
    this.defectsForm.controls['userList'].setValue(formData.userList);
    this.defectsForm.controls['materialNo'].setValue(formData.materialNo);
    this.defectsForm.controls['surveyNo'].setValue(formData.surveyNo);
    this.defectsForm.controls['pmrsNo'].setValue(formData.pmrsNo);
    this.uploadFiles = formData.uploadFile || [];
    this.status = formData.status ? formData.status : {};
    this.group = formData.group ? formData.group : {};
    this.statusName = formData.status ? formData.status['name'] : '';
    this.groupName = formData.group ? formData.group['name'] : '';
    if (this.status) {
      this.colorchanges = this.status.value ? this.status.value : '';
    }
    this.groupColorchanges = formData.group ? formData.group['value'] : '';
  }

  /* * *
  * method for update defects data
  * * */
  putDefectsData() {
    let defectsData = this.getDefectsData();
    defectsData['uploadFile'] = [];
    this.dataServ.updateDefects(this.defectsId, defectsData).then(response => {
      this.updateNotes();
    })
  }

  /* * *
  * method for update notes data
  * * */
  updateNotes() {
    let defectsData = this.getDefectsData();
    let objData = Object.assign({}, this.modalData['notesData'], defectsData, { defectsData: defectsData });
    this.collectionServ.manageCollectionNotes(objData, false);
  }

  /* * *
  * method for clear defects form
  * * */
  clearDefectsForm() {   
    this.defectsForm.controls['description'].setValue("");
    this.defectsForm.controls['startDate'].setValue("");
    this.defectsForm.controls['userList'].setValue("");
    this.defectsForm.controls['materialNo'].setValue("");
    this.defectsForm.controls['surveyNo'].setValue("");
    this.defectsForm.controls['pmrsNo'].setValue("");
    this.status = {};
    this.groupName = '';
    this.statusName = '';
    this.colorchanges = '';
    this.groupColorchanges = '';
  }

  /* * *
  * method for tag mesh locations
  * * */
  tagMeshLocation() {
    this.collectionServ.setPickLocationState(true);
  }

  /* * *
  * method popup location show/hide
  * * */
  getMeshPickLocationState() {
    return this.collectionServ.tagMeshLocationState ? false : true;
  }

  /* * *
  * method for close defects popup
  * * */
  closeDefects() {
    this.popupService.closePopup(this.modalData.collectionType);
    this.broadcastInfo({ src: 'defects', event: 'clear-defects-form-data', data: '' });
  }

  /* * *
  * method for upload files
  * * */
  xuploadFile(event) {
    this.formData = new FormData();
    for (var i = 0; i < event.target.files.length; i++) {
      this.formData.append('file', event.target.files[i]);
    }

    if (this.formData.has('file')) {
      this.dataServ.fileUpload(this.formData).then(response => {
        console.log(" response ", response);
        if (response['success']) {
          let file = response['data'][0]['filePath']
          this.uploadFiles.push(file);
        }
      }).catch((error) => {
        console.log("error ", error);
      });
    } else {
      alert("Invalida file!");
    }
  }
  /* * *
  * method for delete files
  * * */
  deleteAttachment(file, idx) {
    const spliceItem = 1;
    this.uploadFiles.splice(idx, spliceItem);
    let obj = {
      'filePath': file
    }
    this.dataServ.deleteFile(obj).then(response => {
      console.log('fileupload', response);
    }).catch(error => { });
  }

  /* * *
  * method for capture image
  * * */
  openCamera() {
    this.openCameraState = true;
  }

  /* * *
  * method for captured image 
  * * */
  handleImage(webcamImage: WebcamImage) {
    const dataURL = webcamImage['_imageAsDataUrl'];
    this.uploadFiles.push(dataURL);
    this.openCameraState = false;
  }

  /* * *
  * method for upload files
  * * */
  uploadFile(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const dataURL = e.target.result as string;
      this.uploadFiles.push(dataURL);
    };
    reader.readAsDataURL(file);
  }

  /***
   * Get file format type
   */
  getfileFormatImg(base64){
    let formatType = base64.split(';')[0].split('/')[1];
    if(formatType.toLowerCase() == 'png' || formatType.toLowerCase() == 'jpg' || formatType.toLowerCase() == 'jpeg' || formatType.toLowerCase() == 'gif'){
      return true;
    }else{
      return false;
    }
  }
  /* * *
* method for Delete
* * */
  deleteImage(i) {
    this.uploadFiles.splice(i, 1);
  }

}
