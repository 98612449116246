import { Injectable } from '@angular/core';
import { NgxIndexedDBService, ObjectStoreMeta } from "ngx-indexed-db";

@Injectable({
  providedIn: 'root'
})

export class DbService {

  constructor(public dbService: NgxIndexedDBService) { }

  /* *
  * method save multiple data
  * @returns 
  * * */
  bulkSaveData(collectionName, data = []) {
    return new Promise((resolve, reject) => {
      this.dbService.bulkAdd(collectionName, data).subscribe((result) => {
        resolve(result);
      }), error => reject(error)
    })
  }

  /* *
  * method save single data
  * @returns 
  * * */
  saveData(collectionName, data = {}) {
    return new Promise((resolve, reject) => {
      this.dbService.add(collectionName, data).subscribe((result) => {
        resolve(result);
      }), error => reject(error)
    })
  }

  /* *
  * method save single data
  * @returns 
  * * */
  updateData(collectionName, data = {}) {
    return new Promise((resolve, reject) => {
      this.dbService.update(collectionName, data).subscribe((result) => {
        resolve(result);
      }), error => reject(error)
    })
  }

  /* *
  * method delete single data
  * @returns 
  * * */
  deleteData(collectionName, rowNumber) {
    return new Promise((resolve, reject) => {
      this.dbService.deleteByKey(collectionName, rowNumber).subscribe((result) => {
        resolve(result);
      }), error => reject(error)
    })
  }

  /* *
   * method get collections data based on collections name
   * @returns 
   * * */
  getData(collectionName) {
    return new Promise((resolve, reject) => {
      this.dbService.getAll(collectionName).subscribe((response) => {
        resolve(response);
      }), error => reject(error)
    })
  }

  /* *
  * method get collections type
  * @returns 
  * * */
  getCollectionCategory(project) {
    return new Promise((resolve, reject) => {
      this.dbService.getAllByIndex('collectionType', 'venue', IDBKeyRange.only(project)).subscribe((response) => {
        resolve(response);
      }), error => reject(error)
    });
  }

  /* *
  * method get local DB collection info
  * @returns 
  * * */
  getLocalCollectionNotes(project, colelctionType) {
    return new Promise((resolve, reject) => {
      this.dbService.getAllByIndex('collectionNotes', 'venue', IDBKeyRange.only(project)).subscribe((response) => {
        let result = response.filter(e => e['typeCollection'] == colelctionType && e['localDB']);
        resolve(result);
      }), error => reject(error)
    });
  }

  /* *
  * method get collection info
  * @returns 
  * * */
  getCollectionInfo(project, colelctionType) {
    return new Promise((resolve, reject) => {
      this.dbService.getAllByIndex('collectionInfo', 'venue', IDBKeyRange.only(project)).subscribe((response) => {
        let result = response.filter(e => e['annotationType'] == colelctionType);
        resolve(result);
      }), error => reject(error)
    });
  }

  /* *
  * method get collection info
  * @returns 
  * * */
  getCollectionNotes(project, colelctionType) {
    return new Promise((resolve, reject) => {
      this.dbService.getAllByIndex('collectionNotes', 'venue', IDBKeyRange.only(project)).subscribe((response) => {
        let result = response.filter(e => e['typeCollection'] == colelctionType);
        resolve(result);
      }), error => reject(error)
    });
  }

  /* *
  * method get collections type
  * @returns 
  * * */
  getCollectionAnnotation(postFilterParam) {
    let venue = postFilterParam['venue'];
    let colelctionType = postFilterParam['type'].length > 0 ? postFilterParam['type'][0] : 'defects';

    return new Promise((resolve, reject) => {
      this.getCollectionInfo(venue, colelctionType).then((collectionInfo: any) => {
        this.getCollectionNotes(venue, colelctionType).then((collectionNotes) => {
          let cInfo = collectionInfo.length > 0 ? collectionInfo[0] : {};
          let obj = { collectionData: { collectionInfo: cInfo, collectionNotes: collectionNotes } };
          resolve(obj);
        }).catch((error) => { reject(error) });
      }).catch((error) => { reject(error) });
    });
  }

  /* *
  * method delete all collections data based on collections name 
  * * */
  deleteAll(collectionName) {
    return new Promise((resolve, reject) => {
      this.dbService.clear(collectionName).subscribe((response) => {
        resolve(response);
      }), error => reject(error)
    })
  }




}
