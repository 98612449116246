import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../Service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, AfterViewChecked {

  constructor(public loaderServ: LoaderService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void { }

  /* * *
   * method update the data after view rendered
   * * */
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

}
