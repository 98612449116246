import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from 'src/app/Service/navigation.service';
import { DataServiceService } from 'src/app/Service/data-service.service';
import { CollectionManagerService } from 'src/app/Service/collection-manager.service';
import { BrodcastManagerService } from 'src/app/Service/brodcast-manager.service';
import { DbService } from '../../../../DB/db.service';
import { SyncService } from '../../../../DB/sync.service';
import { PopupService } from '../../PopupMasters/popup.service';
import { MatDialog } from '@angular/material/dialog';

// components
import { DefectsComponent } from '../../work-space/defects/defects.component';

const initialObjLen = 0;
const noFindIdx = -1 // -1 means there is no data index
@Component({
  selector: 'app-collection-category-viewer',
  templateUrl: './collection-category-viewer.component.html',
  styleUrls: ['./collection-category-viewer.component.scss']
})

export class CollectionCategoryViewerComponent implements OnInit {
  @Input() collectionType: any = '';
  @Input() selectedNavMenu: any = {};
  @Output() readonly openCollection = new EventEmitter<any>();
  searchInput: any = "";
  notesList: any = []// Notes list data

  selectedNotesList: any = []; // Selected notes list
  deleteActionElemId: any; //delete notes element id handle
  viewActionElemId: any; // view form element id handle
  statusFillNotes: any; //Status fill element id handle
  statusNotes: any; // Status checkbox element id handle
  postFilterParam: any = {
    "date": [],
    "vessel": [],
    "type": [],
    "venue": ""
  } //collection data request params
  collectionParams = {} //it is used to open the pannel
  broadcastSubs: any;
  groupList: any = []; // group list data
  statusList: any = [] // status list data
  searchKey: any = ['tagName', 'formStatus', 'description']; // search key list
  sortKey: string = 'startDate'; //sort key list
  selectedAllState: boolean = false;

  constructor(private dbService: DbService, public syncServ: SyncService, public navServ: NavigationService, private dataServ: DataServiceService, private collectionServ: CollectionManagerService, private broadcastServ: BrodcastManagerService, private popupService: PopupService, public dialog: MatDialog) { }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.broadcastServ.getInstance().next(data);
  }

  ngOnInit(): void {
    //*** method calling for get all collection data*/
    this.fetchAnnotationData();

    this.broadcastSubs = this.broadcastServ.getInstance()
      .subscribe((data: any) => {
        //** Method for refresh Collection Data **//
        if (data.src === "collection-viewer") {
          if (data.event === 'refreshCollectionData') {
            this.refreshData();
          }
        }

        //** Method for refresh params **//
        if (data.src === "collection-close") {
          if (data.event === 'refreshParams') {
            let notes = data['data'];
            if (notes && data['tagStatus']) {
              this.closeTagPoint(notes);
            } else {
              this.refreshParams();
            }
          }
        }

        //** Method for call colection api after upload **//
        if (data.src === "getCollection") {
          if (data.event === 'collectonApi') {
            this.getColectionData();
          }
        }

        //** event instance for collection manager service **//
        if (data.src === "collection-manager") {
          if (data.event === 'show-saved-notes-tagpoint') {
            let notesData = data['data'];
            if (notesData) {
              this.updateSavedNotesData(notesData);
            }
          }
        }

        //** event instance for collection manager service **//
        if (data.src === "defects") {
          if (data.event === 'clear-defects-form-data') {
            this.onCloseDefectsForm();
          }
        }

        //** event instance for switching panel**//
        if (data.src === "systemNav") {
          if (data.event === 'changePanel') {
            this.selectedNavMenu = data.menu;
            this.collectionType = data.menu.id;
            this.collectionParams = {};
            this.postFilterParam['type'] = [];
            this.fetchAnnotationData();
          }
        }

        //** event instance for collection manager service **//
        if (data.src === "sync-service") {
          if (data.event === 'load-annotation-data') {
            this.fetchAnnotationData();
          }
        }

      })
  }

  /* * * *
   * method for remove seleted notes
   * * * */
  fetchAnnotationData() {
    this.collectionParams['collectionType'] = this.collectionType;
    this.postFilterParam['type'].push(this.collectionType);
    this.postFilterParam['venue'] = this.dataServ.projectName;
    let collectionInfo = this.collectionServ.selectedCollection;
    let selectedNavMenuCategory = this.selectedNavMenu['category']
    if (selectedNavMenuCategory === "annotation") {
      if (Object.keys(collectionInfo).length == 0 || collectionInfo.annotationType != this.collectionType) {
        this.getColectionData();
      } else {
        this.setNotesData();
      }
    }
  }

  /* * * *
   * method for show modal dialog box
   * * * */
  async showModalDialog() {
    const { DefectsComponent } = await import('../../work-space/defects/defects.component');
    let modalData = { id: this.collectionType, modalData: this.collectionParams, component: DefectsComponent, title: '' };
    this.popupService.createPopup(modalData);
  }

  /* * * *
  * method for open edit collection forms
  * * * */
  async editCollectionData(notesData) {
    this.collectionParams['notesData'] = notesData;
    const { DefectsComponent } = await import('../../work-space/defects/defects.component');
    let modalData = { id: 'defects', modalData: this.collectionParams, component: DefectsComponent, title: '' };
    this.popupService.createPopup(modalData);
  }

  /***
   * Multiple select notes from list
   * @param event: should pass click event
   * @param notes: should pass selected notes data
   */
  multiSelectNotes(event, notes) {
    event.stopPropagation(); // parent double click avoid     
    if (event.target.id && (event.target.id.includes("action"))) {
      return;
    }
    // this.toggleSelection(notes);
  }

  /***
   * get selected notes status from selected list
   * @param notes: should pass selected notes data
   */
  getSelectedStatus(notes) {
    if (this.selectedNotesList.length > 0) {
      let notesIdx = this.selectedNotesList.findIndex(e => e['_id'] == notes['_id']);
      if (notesIdx > -1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /* * * *
   * method for remove seleted notes
   * * * */
  removeSelectedNotes(id) {
    let index = this.selectedNotesList.findIndex(e => e['_id'] == id);
    if (index > -1) {
      this.selectedNotesList.splice(index, 1);
    }
  }

  /* * * *
   * method for note list select/unselect
   * * * */
  toggleSelection(notes) {
    if (!notes) return;
    if (this.selectedNotesList.length > 0) {
      let notesIdx = this.selectedNotesList.findIndex(e => e['_id'] == notes['_id']);
      if (notesIdx == -1) {
        this.selectedNotesList.push(notes);
        this.showHideTag(notes, true);
      } else {
        this.selectedNotesList.splice(notesIdx, 1);
        this.showHideTag(notes, false);
      }
    } else {
      this.selectedNotesList.push(notes);
      this.showHideTag(notes, true);
    }
    this.getNotesSelectedList();
  }


  /* * *
  * method check is static popup
  * * */
  checkIsStaticPopup(tag) {
    if ((tag.position && (tag.position['x'] !== 0 || tag.position['y'] !== 0 || tag.position['z'] !== 0))) {
      return false;
    } else {
      return true;
    }
  }

  /* * *
  * method for show/hide tag
  * * */
  showHideTag(tag, boo) {
    let tagPoint = tag.tagPoints || {};
    let marker = tag.marker || [];
    let markerId = tag.markerId || '';
    let componentId = tag.componentId || '';

    if (tagPoint && Object.keys(tagPoint).length > 0) {
      /**
       * checking whether its static tag/ dynamic
       * */
      let isStaticPopup = this.checkIsStaticPopup(tagPoint);
      let markerData = { markerId, marker, boo };
      tagPoint.markerData = markerData;

      /**
      * if componentId is there load component then show tag
      * */
      if (componentId) {
        let tag = { tagPoint, boo, staticTag: false };
        //this.tagServ.manageOndemandComponent(componentId, tag, markerData);
      }

      else {
        // this.drawServ.manageCutMarkersFormData(markerData);
        // this.tagServ.manageTag(tagPoint, boo, isStaticPopup);
      }
    }
  }

  /* * * *
  * method for get all collections data
  * * * */
  getColectionData() {
    this.broadcastInfo({ src: 'loader', event: 'showNonBlockLoading', message: "Downloading Collections Data...", state: true });
    this.dbService.getCollectionAnnotation(this.postFilterParam).then(response => {
      this.broadcastInfo({ src: 'loader', event: 'showNonBlockLoading', message: "Downloading Collections Data...", state: false });
      let collectionAnnotation = response['collectionData'];
      this.collectionServ.setSelectedCollectionNotesData(collectionAnnotation['collectionNotes']);
      this.selectedCollection(collectionAnnotation);
      this.setNotesData();
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  /* * * *
  * method for set notes data from collections data
  * * * */
  setNotesData() {
    this.notesList = this.collectionServ.getSelectedCollectionNotesData();
    if (this.notesList.length > initialObjLen) {
      this.getFilterData();
    }
  }

  /* * * *
  * method for set selected collection data in collection service
  * * * */
  selectedCollection(collectionAnnotation) {
    let selectedCollection = collectionAnnotation.collectionInfo
    this.collectionServ.setSelectedCollection(selectedCollection);
  }

  /* * * *
  * method for delete notes data
  * * * */
  deleteNotes(notes) {
    let notesId = notes._id || "";
    if (!notesId) return;
    this.collectionServ.deleteAnnotate(notes);
  }

  /* * * *
  * method for clear notes selection
  * * * */
  clearNotesSelection() {
    this.selectedNotesList = [];
  }

  /* * * *
  * method for refresh panel
  * * * */
  refreshData() {
    this.refreshParams();
    this.notesList = [];
    this.getColectionData();
  }

  /* * * *
  * method for refresh params
  * * * */
  refreshParams() {
    this.clearNotesSelection();
    this.collectionParams = {};
    this.collectionParams['collectionType'] = this.collectionType;
    this.notesList = [];
    this.selectedNotesList = [];
    this.notesList = this.collectionServ.getSelectedCollectionNotesData();
    this.filterData();
  }

  ngOnDestroy() {
    this.broadcastSubs.unsubscribe();
  }

  /* * * * *
  * Search Filter data
  * * * * * */
  searchFilter() {
    this.filterData();
  }

  /* * * * *
  * Search input data
  * * * * * */
  searchData(filterDefaultData) {
    var filterData = [];
    if (filterDefaultData.length > 0) {
      for (let i = 0; i < filterDefaultData.length; i++) {
        if (this.searchKey.length > 0) {
          this.searchKey.forEach((key) => {
            if (typeof filterDefaultData[i][key] === 'string' && typeof this.searchInput === 'string') {
              if (filterDefaultData[i][key].toLowerCase().indexOf(this.searchInput.toLowerCase()) > -1) {
                console.log(key, filterDefaultData[i][key], this.searchInput);
                const found = filterData.some((el: any) => el['_id'] === filterDefaultData['_id']);
                if (!found) {
                  filterData.push(filterDefaultData[i]);
                }
              }
            }
          });
        }
      }
    }
    return filterData;
  }

  /* * * * *
  * clear search
  * * * * * */
  searchClear() {
    this.searchInput = "";
    this.filterData();
  }

  /***
   * get filter panel extend width 
   * @param id: sholud pass element id
   */
  getPanelWidth(id) {
    let rawElem = document.getElementById(id);
    let width = rawElem.offsetWidth;
    return width + 1 + 'px';
  }

  /***
   * Filter panel Toggle show/hide 
   * @param id: sholud pass element id
   */
  filterToggle(id) {
    this.navServ.filterPanelToggle(id);
  }

  /***
   * get filter panel state 
   * @param id:should pass element id
   */
  getFilterPanelState(id) {
    return this.navServ.getNavBarPanelState(id);
  }

  /***
   * get filter panel Data Status/Group
   */
  getFilterData() {
    //** get selectedCollection and store collection values **/
    let selectedCollection = this.collectionServ.getSelectedCollection();
    this.groupList = selectedCollection.groupCollection || [];
    this.statusList = selectedCollection.statusCollection || [];
    this.filterData();
  }

  /***
   * get selected all status 
   */
  getSelectedStatusListState() {
    let statusIdx = this.statusList.findIndex(item => item['status'] == false && item['disabled'] == false);
    return statusIdx > -1 ? false : true;
  }

  /***
   * get disabled all status 
   */
  getDisabledStatusListState() {
    let statusIdx = this.statusList.findIndex(item => item['disabled'] == false);
    return statusIdx > -1 ? false : true;
  }

  /***
   * get selected perticular status change
   * @param status:should pass status data
   */
  getSelectedStatusState(status) {
    let statusIdx = this.statusList.findIndex(item => item['_id'] == status['_id']);
    return statusIdx > -1 ? this.statusList[statusIdx]['status'] : false;
  }

  /***
   * Selected all status change
   */
  selectAllStatus() {
    if (this.getSelectedStatusListState()) {
      this.statusList = this.statusList.map(item => ({ ...item, 'status': false }));
    } else {
      this.statusList = this.statusList.map(item => ({ ...item, 'status': true }));
    }
    this.filterData();
  }

  /***
   * get selected id based status
   * @param status:should pass status data 
   */
  selectedStatus(status) {
    let statusIdx = this.statusList.findIndex(item => item['_id'] == status['_id']);
    if (statusIdx > -1) {
      this.statusList[statusIdx]['status'] = !this.statusList[statusIdx]['status'];
    }
    this.filterData();
  }

  /***
   * get selected all group 
   */
  getSelectedGroupListState() {
    let groupIdx = this.groupList.findIndex(item => item['status'] == false && item['disabled'] == false);
    return groupIdx > -1 ? false : true;
  }

  /***
   * get disabled all group 
   */
  getDisabledGroupListState() {
    let groupIdx = this.groupList.findIndex(item => item['disabled'] == false);
    return groupIdx > -1 ? false : true;
  }

  /***
   * get selected id based group
   * @param group:should pass group data 
   */
  getSelectedGroupState(group) {
    let groupIdx = this.groupList.findIndex(item => item['_id'] == group['_id']);
    return groupIdx > -1 ? this.groupList[groupIdx]['status'] : false;
  }

  /***
   * get selected id based group
   * @param group:should pass group data 
   */
  selectAllGroup() {
    if (this.getSelectedGroupListState()) {
      this.groupList = this.groupList.map(item => ({ ...item, 'status': false }));
    } else {
      this.groupList = this.groupList.map(item => ({ ...item, 'status': true }));
    }
    this.filterData();
  }

  /***
   * get selected id based group
   * @param group:should pass group data 
   */
  selectedGroup(group) {
    let groupIdx = this.groupList.findIndex(item => item['_id'] == group['_id']);
    if (groupIdx > -1) {
      this.groupList[groupIdx]['status'] = !this.groupList[groupIdx]['status'];
    }
    this.filterData();
  }

  /**
   * Filter all data from list 
   */
  filterData() {
    var defaultData = this.collectionServ.getSelectedCollectionNotesData();
    var filterData = [];
    // Status filter list from data
    if (!this.getSelectedStatusListState()) {
      filterData = defaultData.filter(item => this.statusList.find(status => (status['status'] === true && status.name == item['formStatus'])));
    } else {
      filterData = defaultData;
    }

    // Group filter list from data
    if (!this.getSelectedGroupListState()) {
      filterData = filterData.filter(item => this.groupList.find(group => (group['status'] === true && group.name == item['tagName'])));
    }

    // Search filter list from data
    if (this.searchInput != '') {
      filterData = this.searchData(filterData);
    }
    this.notesList = filterData;
  }

  /***
   * Set disabled data list status update
   */
  setListcheckStatus() {
    //status disabled data status change
    if (this.statusList.length > 0 && this.notesList.length > 0) {
      this.statusList.forEach(status => {
        let noteIdx = this.notesList.findIndex(notes => status.name == notes['formStatus'])
        if (noteIdx == -1) {
          status['disabled'] = true;
          status['status'] = false;
        } else {
          status['disabled'] = false;
          status['status'] = true;
        }
      })
    } else {
      if (this.statusList.length > 0 && this.notesList.length == 0) {
        this.statusList.map(item => ({ ...item, 'disabled': true }));
        this.statusList.map(item => ({ ...item, 'status': false }));
      }
    }

    //group disabled data status change
    if (this.groupList.length > 0 && this.notesList.length > 0) {
      this.groupList.forEach(group => {
        let noteIdx = this.notesList.findIndex(notes => group.name == notes['tagName'])
        if (noteIdx == -1) {
          group['disabled'] = true;
          group['status'] = false;
        } else {
          group['disabled'] = false;
          group['status'] = true;
        }
      })
    } else {
      if (this.groupList.length > 0 && this.notesList.length == 0) {
        this.groupList.map(item => ({ ...item, 'disabled': true }));
        this.groupList.map(item => ({ ...item, 'status': false }));
      }
    }
  }

  /* * *
  * method for excel upload
  * * */
  onFileSelection(event) {
    let file = event.target.files;
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file[0].name)[1];
    this.collectionServ.excelUpload(ext, file);
  }

  /**
   * Get notes all selected status
   */
  getNotesSelectedList() {
    if (this.selectedNotesList.length == this.notesList.length) {
      this.selectedAllState = true;
    } else {
      this.selectedAllState = false;
    }
  }

  /**
   * method for get selection states
   */
  getSelectionIconState() {
    if (this.selectedAllState) {
      return { outline: true, icon: 'check_circle' };
    } else if (this.selectedNotesList.length > 0) {
      return { outline: true, icon: 'remove_circle_outline' };
    } else {
      return { outline: false, icon: 'radio_button_unchecked' };
    }
  }

  /**
   * method for manage notes selections
   */
  manageNotesSelection() {
    if (this.selectedNotesList.length == this.notesList.length) {
      this.selectAllNotesList();
    } else if (this.selectedNotesList.length > 0) {
      this.clearSelectedNotes();
    } else {
      this.selectAllNotesList();
    }
  }

  /**
  * method for clear selected notes
  */
  clearSelectedNotes() {
    this.selectedNotesList.forEach(notes => {
      this.showHideTag(notes, false);
    });
    this.selectedNotesList = [];
  }

  /**
   * select notes all selected status
   */
  selectAllNotesList() {
    this.selectedAllState = !this.selectedAllState;
    if (this.selectedAllState) {
      this.selectedNotesList = JSON.parse(JSON.stringify(this.notesList));
      //tag show all selected list
      this.selectedNotesList.forEach(notes => {
        this.showHideTag(notes, true);
      });
    } else {
      this.selectedNotesList = [];
      //tag hide all selected list
      this.notesList.forEach(notes => {
        this.showHideTag(notes, false);
      });
    }
  }

  /* * * *
  * method for close tagPoint from tag form
  * * * */
  closeTagPoint(notes) {
    if (notes) {
      this.toggleSelection(notes);
    }
    this.collectionParams = {};
    this.collectionParams['collectionType'] = this.collectionType;
    //this.popupService.closePopup(this.collectionType);
  }

  /* * * *
  * method for update saved notes data and show last saved tag point
  * * * */
  updateSavedNotesData(notes) {
    this.notesList = this.collectionServ.getSelectedCollectionNotesData();
    if (notes && notes.tagShapeId) {
      let tagPoint = notes.tagPoints || {};
      if (tagPoint && Object.keys(tagPoint).length > 0) {
        let idx = this.selectedNotesList.findIndex(e => e['_id'] == notes['_id']);
        if (idx > -1) {
          this.selectedNotesList.splice(idx, 1);
        }
        this.toggleSelection(notes);
      }
    }
    this.onCloseDefectsForm();
  }

  /* * * *
  * method for clear defects form 
  * * * */
  onCloseDefectsForm() {
    this.collectionParams = {};
    this.collectionParams['collectionType'] = this.collectionType;
  }

  /* * * *
  * method for show tags inside boundary elements 
  * * * */
  showTagsInsideBoundary(tagShapeIds = []) {
    tagShapeIds.forEach((tagShapeId) => {
      let notes = this.notesList.find(e => e.tagShapeId == tagShapeId);
      if (!notes) return;
      const isFound = this.selectedNotesList.some(e => e['_id'] == notes['_id']);
      if (!isFound) {
        this.toggleSelection(notes);
      }
    });
  }

  /* * * *
  * method for sync collection data
  * * * */
  syncCollectionData() {
    this.syncServ.syncCollectionData();
  }
}
