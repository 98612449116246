/* *
*The "Broacast Manager Service" manages the communication between the components.
* * */
import { Injectable } from '@angular/core';
import * as Rx from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrodcastManagerService {
  private instance!: Rx.Subject<any>;

  constructor() { }

  /* *
  * The "Broacast Manager Service" createInstance.
  * * */
  createInstance(): Rx.Subject<any> {
    const instance = new Rx.Subject<any>();
    return instance;
  }

  /* *
  * The "Broacast Manager Service" getInstance.
  * * */
  public getInstance(): Rx.Subject<any> {
    if (!this.instance) {
      this.instance = this.createInstance();
    }
    return this.instance;
  }

}
