<!-- collectionCategoryViewer section start -->
<div class="collectionCategoryViewerSection padding-12 custom-input" id="collection-category">
    <!-- <div class="mobile_close_btn mobileView">
            <mat-icon (click)="navServ.toggleNavBarPanel('sideBar')">close</mat-icon>
    </div> -->
    <!-- Header section start -->
    <div class="headSection display-flex container-100">
        <div class="leftSection padding-top-10 margin-left-16">{{selectedNavMenu['name']}}</div>
        <div class="rightSection">
            <button mat-icon-button color="primary" matTooltip="Sync Data" matTooltipPosition="above" (click)="syncCollectionData()">
                <mat-icon class="margin-right-4">sync</mat-icon>
            </button>
            <button mat-icon-button color="primary" matTooltip="Add {{selectedNavMenu['name']}}" matTooltipPosition="above" (click)="showModalDialog()">
                <mat-icon class="margin-right-4">add</mat-icon>
            </button>
        </div>
    </div>
    <!-- Header section end -->

    <!-- Search section start -->
    <mat-form-field floatLabel="always" class="container-100">
        <input matInput [(ngModel)]="searchInput" type="text" placeholder="Search {{selectedNavMenu['name']}}" (keyup)="searchFilter()" >
        <button matPrefix mat-icon-button>
            <mat-icon class="mat-icon-18">search</mat-icon>
        </button>
        <button matSuffix *ngIf="searchInput!=''" mat-icon-button aria-label="Clear" (click)="searchClear()">
            <mat-icon class="mat-icon-18">close</mat-icon>
        </button>
    </mat-form-field>
    <!-- Search section end -->

    <!-- <div class="selectAllSection list-item-text-color border-bottom-1 rounded-bottom-0 border-bottom-solid display-flex padding-left-8 padding-right-8 padding-bottom-2 padding-top-4 pointer hover rounded-5 mat-app-transparent-background"
        (click)="manageNotesSelection()">
        <div class="leftSection selectionLeft">
            <div class="head5-text text-weight-500 text-truncate">Select All</div>
        </div>       
        <div class="rightSection">
            <mat-icon class="material-icons-outlined mat-icon-18"
                [class.material-icons-outlined]="getSelectionIconState()['outline']">{{getSelectionIconState()['icon']}}</mat-icon>
        </div>
    </div> -->
    
    <!-- Collection list section start -->
    <div class="collectionList">
        <mat-list role="list" class="padding-top-0">
            <ng-template ngFor let-defectIndex="index" let-notes [ngForOf]="notesList">
                <!-- Notes list item start -->
                <mat-list-item role="listitem"
                    [ngClass]="getSelectedStatus(notes)?'cardActive  cardHover pointer border-bottom-1 border-bottom-solid mat-list-item mat-focus-indicator padding-0 margin-bottom-4':'cardHover mat-app-transparent-background pointer border-bottom-1 border-bottom-solid mat-list-item mat-focus-indicator padding-0 margin-bottom-4'">
                    <div class="listData padding-4 position-relative container-97 margin-top-8 margin-bottom-8 margin-0"
                        (click)="multiSelectNotes($event,notes)" id="notesList"
                        [ngStyle]="{'border-left-color':notes.group&&notes.group.value}">
                        <div class="listItem position-relative display-flex container-100">
                            <!-- Notes header section start -->
                            <div class="leftSection">
                                <div class="head5-text text-weight-500 text-truncate">{{notes.tagName}}</div>
                            </div>
                            <!-- Notes Action section start -->
                            <div class="rightSection">
                                <div class="rightAction">
                                    <!-- view notes element start -->
                                    <mat-icon matTooltip="View {{selectedNavMenu['name']}}" matTooltipPosition="above"
                                        id="action_view"
                                        class="marigin-left-5 child-hidden pointer hover margin-left-4 mat-icon-18 padding-left-2 padding-right-4 "
                                        (click)="editCollectionData(notes)">wysiwyg</mat-icon>
                                    <!-- view notes element end -->
                                    <!-- Delete notes element start -->
                                    <mat-icon matTooltip="Delete" matTooltipPosition="above" id="action_delete"
                                        class="marigin-left-5 child-hidden pointer hover margin-left-4 mat-icon-18 padding-left-2 padding-right-4 "
                                        (click)="deleteNotes(notes)">delete</mat-icon>
                                    <!-- Delete notes element end -->
                                    <!-- Status fill circle element start -->
                                    <mat-icon matTooltip="Status" matTooltipPosition="above" id="status"
                                        class="marigin-left-5 child-none pointer hover mat-icon-18 padding-left-2 padding-right-4 statusColor"
                                        [ngClass]="selectedNotesList.length==0?'display-initial':'display-none'"
                                        [ngStyle]="{'color':notes.status.value}">circle</mat-icon>
                                    <!-- Status fill circle element end -->
                                    <!-- Status checkbox element start -->
                                    <mat-icon matTooltip="Status" matTooltipPosition="above" id="status_checkbox"
                                        class="marigin-left-5 child-view pointer hover mat-icon-18 padding-left-2 padding-right-4"
                                        [ngClass]="selectedNotesList.length>0?getSelectedStatus(notes)?'display-initial material-icons-outlined':'':'display-none'"
                                        (click)="multiSelectNotes($event,notes)"
                                        [ngStyle]="{'color':notes.status.value}">{{getSelectedStatus(notes)?'check_circle':'radio_button_unchecked'}}</mat-icon>
                                    <!-- Status checkbox element end -->
                                </div>
                            </div>
                            <!-- Notes Action section end -->
                        </div>
                        <!-- Notes header section end -->
                        <!-- Notes description start -->
                        <div class="paragraph-text  text-truncate notesDescription">
                            {{notes.description}}
                        </div>
                        <!-- Notes description end -->
                    </div>
                </mat-list-item>
            </ng-template>
        </mat-list>
    </div>
    <!-- Collection list section start -->
</div>
<!-- Collection section end -->

<!-- Filter panel start -->
<div class="filterPanel position-absolute width-200 rounded-5 padding-12 mat-app-transparent-background"
    [style.left]="getPanelWidth('collection-category')" *ngIf="getFilterPanelState('filterPanel')">
    <!-- Filter panel head section start -->
    <div class="headSection display-flex container-100">
        <div class="leftSection padding-top-10 head5-text">Filter</div>
        <div class="rightSection display-flex">
            <button mat-icon-button (click)="filterToggle('filterPanel')">
                <mat-icon class="margin-right-4">close</mat-icon>
            </button>
        </div>
    </div>
    <!-- Filter panel head section end -->

    <!-- Filter panel content section start -->
    <div class="contentSection">
        <!-- Status section start -->
        <div class="ItemList">
            <div class="listItem mat-app-transparent-background rounded-5 margin-bottom-12" *ngIf="statusList.length>0">
                <!-- Head section start -->
                <div (click)="selectAllStatus()" [class.disabled]="getDisabledStatusListState()"
                    class="fiterHead hover pointer display-flex padding-left-8 mat-app-transparent-background rounded-5">
                    <div class="leftSection padding-top-10 head5-text text-color-white">Status</div>
                    <div class="rightSection">
                        <mat-icon [class.visibility-hidden]="getDisabledStatusListState()"
                            class="mat-icon-18 padding-top-8 padding-bottom-8 padding-left-2 padding-right-4 material-icons-outlined">{{getSelectedStatusListState()?'check_box':'check_box_outline_blank'}}</mat-icon>
                    </div>
                </div>
                <!-- Head section end -->

                <!-- Sort list section start -->
                <div class="contentList">
                    <!-- Assigned data list start -->
                    <ng-template ngFor let-defectIndex="index" let-status [ngForOf]="statusList">
                        <div (click)="selectedStatus(status)" *ngIf="!status.disabled"
                            class="dataItem pointer text-capitalize hover display-flex padding-left-8">
                            <div class="leftSection padding-top-10 head5-text">
                                <span class="colorLabel" [ngStyle]="{'background-color':status.color}"></span>
                                {{status.name?status.name:'Un Assigned'}}
                            </div>
                            <div class="rightSection">
                                <mat-icon
                                    class="pointer mat-icon-18 padding-top-8 padding-bottom-8 padding-left-2 padding-right-4 material-icons-outlined">{{getSelectedStatusState(status)?'check_box':'check_box_outline_blank'}}</mat-icon>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Assigned data list end -->

                    <!-- disabled data list start -->
                    <ng-template ngFor let-defectIndex="index" let-status [ngForOf]="statusList">
                        <div (click)="selectedStatus(status)" *ngIf="status.disabled"
                            class="dataItem pointer text-capitalize hover display-flex padding-left-8 disabled">
                            <div class="leftSection padding-top-10 head5-text">
                                <span class="colorLabel" [ngStyle]="{'background-color':status.color}"></span>
                                {{status.name?status.name:'Un Assigned'}}
                            </div>
                            <div class="rightSection">
                                <mat-icon
                                    class="pointer visibility-hidden mat-icon-18 padding-top-8 padding-bottom-8 padding-left-2 padding-right-4 material-icons-outlined">{{getSelectedStatusState(status)?'check_box':'check_box_outline_blank'}}</mat-icon>
                            </div>
                        </div>
                    </ng-template>
                    <!-- disabled data list end -->
                </div>
                <!-- Sort list section end -->
            </div>
        </div>
        <!-- Status section end -->

        <!-- Group section start -->
        <div class="ItemList">
            <div class="listItem mat-app-transparent-background rounded-3 margin-bottom-12" *ngIf="groupList.length>0">
                <!-- Head section start -->
                <div (click)="selectAllGroup()" [class.disabled]="getDisabledGroupListState()"
                    class="fiterHead hover pointer display-flex padding-left-8 mat-app-transparent-background rounded-5">
                    <div class="leftSection padding-top-10 head5-text text-color-white">Group</div>
                    <div class="rightSection">
                        <mat-icon [class.visibility-hidden]="getDisabledGroupListState()"
                            class="pointer  mat-icon-18 padding-top-8 padding-bottom-8 padding-left-2 padding-right-4 material-icons-outlined">{{getSelectedGroupListState()?'check_box':'check_box_outline_blank'}}</mat-icon>
                    </div>
                </div>
                <!-- Head section end -->

                <!-- Sort list section start -->
                <div class="contentList">
                    <!-- Assigned data list start -->
                    <ng-template ngFor let-defectIndex="index" let-group [ngForOf]="groupList">
                        <div (click)="selectedGroup(group)" [ngStyle]="{'border-left-color':group.color}"
                            *ngIf="!group.disabled"
                            class="dataItem pointer text-capitalize hover display-flex padding-left-8 border-left-2 border-left-solid ">
                            <div class="leftSection padding-top-10 head5-text">{{group.name==""?'Un
                                Assigned':group.name}}</div>
                            <div class="rightSection">
                                <mat-icon
                                    class="pointer  mat-icon-18 padding-top-8 padding-bottom-8 padding-left-2 padding-right-4 material-icons-outlined">{{getSelectedGroupState(group)?'check_box':'check_box_outline_blank'}}</mat-icon>
                            </div>
                        </div>
                    </ng-template>
                    <!-- Assigned data list end -->

                    <!-- disabled data list start -->
                    <ng-template ngFor let-defectIndex="index" let-group [ngForOf]="groupList">
                        <div (click)="selectedGroup(group)" [ngStyle]="{'border-left-color':group.color}"
                            *ngIf="group.disabled"
                            class="dataItem pointer text-capitalize hover display-flex padding-left-8 border-left-2 border-left-solid disabled">
                            <div class="leftSection padding-top-10 head5-text">{{group.name==""?'Un
                                Assigned':group.name}}</div>
                            <div class="rightSection">
                                <mat-icon
                                    class="pointer visibility-hidden mat-icon-18 padding-top-8 padding-bottom-8 padding-left-2 padding-right-4 material-icons-outlined">{{getSelectedGroupState(group)?'check_box':'check_box_outline_blank'}}</mat-icon>
                            </div>
                        </div>
                    </ng-template>
                    <!-- disabled data list end -->
                </div>
                <!-- Sort list section end -->
            </div>
        </div>
        <!-- Group section end -->
    </div>
    <!-- Filter panel content section end -->
</div>
<!-- Filter panel end -->