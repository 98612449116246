<!-- Block loader section start -->
<div class="block-loader loaderPart" *ngIf="loaderServ.loaderData['key']=='block'">
    <div class="loaderSection">
        <mat-spinner [diameter]="75" class="spinner"></mat-spinner>
        <p class="paragraph-text">{{loaderServ.loaderData['message']}}</p>
    </div>

</div>
<!-- Block loader section end -->

<!-- Block transparent loader section start -->
<div class="block-trans-loader loaderPart" *ngIf="loaderServ.loaderData['key']=='block-transparent'">
    <div class="loaderSection">
        <mat-spinner [diameter]="75"></mat-spinner>
        <p class="paragraph-text">{{loaderServ.loaderData['message']}}</p>
    </div>

</div>
<!-- Block transparent loader section end -->

<!-- Unblock loader section start -->
<div class="unblock-loader" *ngIf="loaderServ.loaderData['key']=='unblock'">
    <div class="loaderSection">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
<!-- Unblock loader section end -->

<!-- Sync loader section start -->
<div class="block-loader loaderPart" *ngIf="loaderServ.loaderData['key']=='sync'">
    <div class="loaderSection">
        <img src="../../../../assets/image/loading.jpg" class="syncLoader">
        <p class="paragraph-text">{{loaderServ.loaderData['message'] || 'Loading...'}}</p>
    </div>

</div>
<!-- Block loader section end -->