<div class="container">
    <div class="camera-main">
        <webcam *ngIf="showWebcam" [height]="300" [width]="300" [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)" [allowCameraSwitch]="allowCameraSwitch"
            [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions"
            (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)"></webcam>
        <div class="margin-top-15">
            <button class="" mat-mini-fab (click)="triggerSnapshot();">
                <mat-icon class="">add_a_photo</mat-icon>
            </button>
        </div>
        <!-- <div class="actionBtn" (click)="showNextWebcam(true);" title="Switch Camera">🔁</div> -->
    </div>
</div>