import { Injectable, ComponentRef, OnInit, ViewContainerRef } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PopupService implements OnInit {
  private modals: any[] = [];

  // popup container component ref: to handle single instance of PopupContainer
  popupContainerRef: any;
  elements = [];

  ngOnInit(): void {
  }

  /**
   * addModal :- push the modal to the modals collection
   * @param modal : popup modal
   */
  addModal(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  /**
   * removeModal :- remove the modal popup from the collectio
   * @param id : id attribute of the modal popup
  */
  removeModal(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  createPopup(modalData: any) {
    if (this.IsPopupAvailable(modalData.id)) {
      return;
    }
    const componentRef = this.popupContainerRef.createComponent(modalData.component);

    // assigned properties of popup component instance from modalDate parameter
    componentRef.instance.title = modalData.title;
    componentRef.instance.modalData = modalData.modalData;
    componentRef.instance.modalId = modalData.id;
    if (modalData.multiple != undefined) {
      componentRef.instance.multiple = modalData.multiple;
    }
    if (modalData.popupCallback != undefined) {
      componentRef.instance.outdata.subscribe(data =>
        modalData.popupCallback(data)
      );
    }
    if (modalData.modal != undefined) {
      componentRef.instance.modal = modalData.modal;
    } else {
      componentRef.instance.modal = true;
    }
    this.elements.push(componentRef.instance);
    // assigned properties of popup component instance from modalDate parameter ends.

  }

  /**
   * IsPopupAvailable: checks whether the popup already opened
   * @param id : id of modal popul
   * @returns boolean
   */
  IsPopupAvailable(id: any) {
    let cnt = 0;
    while (cnt < this.elements.length) {
      if (this.elements[cnt].modalId == id) {
        return true;
      }
      cnt++;
    }
    return false;
  }

  /**
   * openPopup :- opens respective modal popup from the modals collection
   * @param id : id attribute of the modal popup
   */
  openPopup(id: string) {
    // open modal specified by id
    const modal = this.modals.find(x => x.id === id);
    modal.openModal();
  }

  /**
   * closePopup :- closes the modal popup 
   * @param id : id atribute of the modal popup
   */
  closePopup(id: string) {
    const modal = this.modals.find(x => x.id === id);
    if(modal)
    modal.closeModal();
    this.removeModal(id);
    this.removePopup(id);
  }

  removePopup(id) {
    // remove from PopupContainer
    let cnt = 0;
    while (cnt < this.elements.length) {
      if (this.elements[cnt].modalId == id) {
        this.popupContainerRef.remove(cnt);
      }
      cnt++;
    }
    // remove from ComponentRef instance
    if (id != undefined) {
      this.elements = this.elements.filter(x => x.modalId != id);
    }
  }

  // **********Unused methods. Kept for reference**********
  /**
 * clearPopup :- clear popups from the popupContainerRef: viewContainerRef
 */
  // clearPopup(id:any = undefined){
  //   // clear popups from popupContainerRef
  //   let cnt = 0;
  //   while(cnt < this.elements.length){
  //     if ( !this.IsPopupMultiple(this.elements[cnt]) || 
  //           (this.IsModalPopup(this.elements[cnt]) && this.IsPopupMultiple(this.elements[cnt])) ||
  //            this.IsCurrentModal(id,cnt)){
  //       this.popupContainerRef.remove(cnt);
  //     }
  //     cnt++;
  //   }
  //   if (id != undefined){
  //     this.elements = this.elements.filter(x => x.modalId != id);
  //   }
  //   this.elements = this.elements.filter(x => 
  //     (x.multiple == true) || (x.modal != undefined && x.modal == false));
  // }

  /**
   * filterModal: filtered Modal popup collection
   * @param popupref : popup instance reference
   * @param id : popup instance id
   * @returns : popup instance ref
   */
  // filterModal(popupref:any, id:any = undefined){
  //   return ;
  // }
  
  /**
   * IsPopupMultiple: checks whether popup component is multiple type
   * @param popupinstanceref : popup component instance
   * @returns boolean
   */
  // IsPopupMultiple(popupinstanceref){
  //   if (popupinstanceref.multiple != undefined && popupinstanceref.multiple == true){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }

  /**
   * IsModalPopup: checks whether popup component is modal type
   * @param popupinstanceref : popup component instance
   * @returns 
   */
  // IsModalPopup(popupinstanceref){
  //   if (popupinstanceref.modal != undefined && popupinstanceref.modal == true){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }

  /**
   * IsCurrentModal: checks whether popup is the current popup
   * @param id : popup component id
   * @returns 
   */
  // IsCurrentModal(id,index){
  //   if (this.elements[index].modalId == id){
  //     return true;
  //   }else{
  //     return false;
  //   }
  // }
  // **********Unused methods. Kept for reference ends.**********

}
