<app-modal class="defectspopup-modal" [IsModal]="true">
    <div class="defects-dialog mat-card-background">
        <div class="display-flex">
            <div class="container-50">
                <h4 class="formheader">Defects Form</h4>
            </div>
            <div class="container-50 text-right">
                <button class="float-right closeBtn" mat-icon-button (click)="closeDefects()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

        <form [formGroup]="defectsForm">
            <div class="status-row justify-content-center custom-input">
                <div class="comments-column">
                    <mat-form-field floatLabel="always" class="example-full-width" appearance="fill">
                        <!-- <mat-label>PMRS</mat-label> -->
                        <input matInput placeholder="PMRS" formControlName="pmrsNo">
                    </mat-form-field>
                </div>
                <div class="comments-column">
                    <mat-form-field floatLabel="always" class="example-full-width" appearance="fill">
                        <!-- <mat-label>Survey No</mat-label> -->
                        <input matInput placeholder="Survey No" formControlName="surveyNo">
                    </mat-form-field>
                </div>
                <div class="comments-column">
                    <mat-form-field floatLabel="always" class="example-full-width" appearance="fill">
                        <!-- <mat-label>Material No</mat-label> -->
                        <input matInput placeholder="Material No" formControlName="materialNo">
                    </mat-form-field>
                </div>
                <div class="comments-column">
                    <button mat-button [matMenuTriggerFor]="statusMenus" class="rounded-5 padding-right-4">
                        <p class="text-truncate margin-0">
                            <span class="colorLabel" [ngStyle]="{'background-color':colorchanges}"></span>
                            <span *ngIf="!statusName" class="paragraph-text">Status</span>
                            <span *ngIf="statusName" class="paragraph-text">{{statusName}}</span>
                        </p>
                        <mat-icon class="menuArrow">arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #statusMenus="matMenu">
                        <ng-container *ngFor="let status of statusList; let i=index;">
                            <button mat-menu-item class="menuBtn" *ngIf="status.name">
                                <p class="groupAct" (click)="fetchStatus(status._id)">
                                    <span class="colorLabel" [ngStyle]="{'background':status.color}"></span>
                                    <span>{{status.name}}</span>
                                </p>
                            </button>
                        </ng-container>
                    </mat-menu>
                </div>
                <div class="comments-column margin-top-15">
                    <button mat-button [matMenuTriggerFor]="groupMenus"
                        class="rounded-5 padding-left-8 padding-right-4">
                        <p class="text-truncate margin-0">
                            <span class="colorLabel" [ngStyle]="{'background-color':groupColorchanges}"></span>
                            <span *ngIf="!groupName" class="paragraph-text">Group</span>
                            <span *ngIf="groupName" class="paragraph-text">{{groupName}}</span>
                        </p>
                        <mat-icon class="menuArrow">arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #groupMenus="matMenu">
                        <ng-container *ngFor="let group of groupList; let i=index;">
                            <button mat-menu-item class="menuBtn" (click)="fetchGroup(group._id)" *ngIf="group.name">
                                <p class="groupAct">
                                    <span class="colorLabel" [ngStyle]="{'background-color':group.color}"></span>
                                    <span>{{group.name}}</span>
                                </p>
                            </button>
                        </ng-container>
                    </mat-menu>
                </div>
            </div>
            <!-- input section end -->

            <!-- textarea section start -->
            <div class="status-row  margin-top-8">
                <div class="container-100 text-bottom margin-bottom-8 margin-top-5">
                    <div class="text-left">Description</div>
                </div>

            </div>
            <div class="status-row">
                <div class="container-100">
                    <textarea class="textarea rounded-5" matInput formControlName="description"></textarea>
                </div>
            </div>
            <!-- textarea section end -->

            <!--** upload data show **-->
            <!-- <div class="margin-top-5">
                    <div *ngFor="let uploadFile of uploadFiles; let idx = index">
                        <div><mat-icon class="mat-icon-18 download padding-right-3">download</mat-icon> <a
                                class="text-truncate paragraph-text display-inline-block mat-tab-link attachment"
                                [href]="uploadFile" target="_blank">{{uploadFile}}</a><mat-icon
                                class="close head4-text pointer position-relative" color="warn"
                                (click)="deleteAttachment(uploadFile,idx)">close</mat-icon></div>
                    </div>
                </div> -->
            <!-- uploaded image start -->
            <div class="status-row container-100 margin-top-15 display-flex">
                <div class="attachmentList margin-bottom-8">
                    <ng-container  *ngFor="let src of uploadFiles; let idx = index">
                        <div class="imageContainer" *ngIf="getfileFormatImg(src)" [ngStyle]="{'background-image': 'url(' +src+ ')'}">
                            <!-- <img [src]="src" class="previewImage" /> -->
                            <mat-icon class="closeIcon" (click)="deleteImage(idx)">close</mat-icon>
                        </div>
                        <div class="fileContainer" *ngIf="!getfileFormatImg(src)" style="background-image: url('../../../../../assets/image/file.png')">
                            <!-- <img [src]="src" class="previewImage" /> -->
                            <mat-icon class="closeIcon" (click)="deleteImage(idx)">close</mat-icon>
                        </div>
                    </ng-container>
                </div>
            </div>
            <!-- uploaded image end -->
            <!-- upload section start -->
            <div class="status-row container-100 margin-top-4 text-left">
                <div class="">
                    <button class="margin-right-4" mat-mini-fab (click)="fileUpload.value='';fileUpload.click();">
                        <mat-icon class="">attach_file</mat-icon>
                    </button>
                    <button mat-mini-fab class="" (click)="openCamera()">
                        <mat-icon class="">photo_camera</mat-icon>
                    </button>
                    <input type="file" accept="image/*;" #fileUpload name="file" (change)="uploadFile($event)"
                        style="display:none;">
                </div>
            </div>
            <!-- upload section end -->
        </form>

        <div class="status-row container-100 margin-top-15 text-right">
            <button mat-button class="btn-cancel margin-right-10" mat-dialog-close
                (click)="closeDefects()">Cancel</button>
            <button mat-raised-button color="primary" class="btn-submit margin-left-12"
                (click)="this.saveDefects()">Save
            </button>
        </div>

    </div>

    <!-- camera component start -->
    <div *ngIf="openCameraState" class="camera-container">
        <app-camera (pictureTaken)="handleImage($event)"></app-camera>
    </div>
    <!-- camera component end -->

</app-modal>