import { Component } from '@angular/core';
import { BrodcastManagerService } from 'src/app/Service/brodcast-manager.service';
import { CollectionManagerService } from 'src/app/Service/collection-manager.service';
import { NavigationService } from 'src/app/Service/navigation.service';
@Component({
  selector: 'app-system-nav',
  templateUrl: './system-nav.component.html',
  styleUrls: ['./system-nav.component.scss']
})

export class SystemNavComponent {
  broadcastSubs: any;
  activePanelList: any = []; //first all panel's hidden after based on cofig it will behave

  constructor(public navServ: NavigationService, private collectionServ: CollectionManagerService, private broadcastServ: BrodcastManagerService) { }

  ngOnInit(): void {
    this.navServ.toggleNavBarPanel('sideBar');

    this.broadcastSubs = this.broadcastServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "work-space") {
          if (data.event === 'open-default-nav-menu') {
            this.openDefaultMenu();
          }
        }
      })
  }

  /**
   * default open
   */
  openDefaultMenu() {
    let menu = this.navServ.selectedMenu.filter(e => e['category'] == 'annotation');
    if (menu.length > 0) {
      this.setNavPanel(menu[0]);
    }
  }



  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.broadcastServ.getInstance().next(data);
  }

  /* *
  * method for push active panel list 
  * * */
  pushActivePanel(panel) {
    const isFound = this.activePanelList.some(e => e == panel);
    if (!isFound) {
      this.activePanelList.push(panel);
    }
  }

  /* *
  * method for whether panel should destroy or just visibity hide  
  * * */
  getPanelState(panel) {
    /* *
     * code if dynamic collections panel handling
     * * */
    if (panel == 'collection') {
      let cpanel = this.navServ.getActiveNavPanel();
      let state = this.navServ.getCollectionList().includes(cpanel);
      if (state) {
        this.pushActivePanel(cpanel);
      }
      let boo = this.activePanelList.includes(cpanel);
      return { show: boo, class: state ? 'showSecton' : 'hideSection' };
    }

    /* *
    * code if static panels handling
    * * */
    else {
      if (this.navServ.getPanelShowHideRule(panel) === 'none') {
        let state = this.navServ.getActiveNavPanel() === panel ? true : false;
        return { show: state, class: '' };
      } else {
        let state = this.navServ.getActiveNavPanel() === panel ? true : false;
        if (state) {
          this.pushActivePanel(panel);
        }
        let boo = this.activePanelList.includes(panel);
        return { show: boo, class: state ? 'showSecton' : 'hideSection' };
      }
    }
  }

  /* *
  * method for get sidepanel state
  * * */
  getSidepanelState(key) {
    return this.navServ.getNavBarPanelState(key) ? 'showSecton' : 'hideSection';
  }

  /* *
  * method for set nav panel state
  * * */
  setNavPanel(menu) {
    this.navServ.setNavPanel(menu, 'sidePanel');
    this.broadcastInfo({ src: 'systemNav', event: 'changePanel', menu: menu });
  }

}
