/**        
 * User: Arifa.M
 * Date: 24/12/2022
 * Time: 16:00
 * Purpose : The "DataServiceService" manages the all local data and backend data communication       
 */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../Service/config.service';
import { BrodcastManagerService } from './brodcast-manager.service';

@Injectable({
  providedIn: 'root'
})

export class DataServiceService {
  private _jsonURL = '../assets/project.config.json';
  private metaJson = "data/metaData.json";
  private baseURL: any = '';
  public project_config: any;
  private DefaultProject = 'slms';
  private config: any;
  public projectName: any = ""; //store projectName
  private metaData: any = {};
  modelObjectData: any = []; //it contains model object data
  selectedProjectData: any; //selected project data assign
  datauxview: any;
  dfx: any;
  allElementsIDs: any = [];
  allElements: any = [];//store loaded elements
  venueLoadedState: boolean = false;
  projectJson: any = []; // project list

  constructor(public configServ: ConfigService, public broadcastServ: BrodcastManagerService) { }

  /* * * *
  * method for generate the token sign
  * * * */
  signAuth(data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.configServ.postData(self.baseURL + 'signAuth/', data).subscribe(data => {
        self.configServ.setToken(data);
        resolve(true);
      },
        error => resolve(false)
      );
    });
  }

  /* * *
  * method for get access token
  * * */
  getAccessToken() {
    return this.configServ.getAccessToken();
  }

  /* * *
  * method for get refersh token
  * * */
  getRefershToken() {
    return this.configServ.getRefershToken();
  }

  /* * * *
  * method for set meta data
  * * * */
  setMetaData(data) {
    this.metaData = data;
    this.setModelObjectData();
  }

  /* * * *
  * method for get meta data
  * * * */
  getMetaData() {
    let self = this;
    let url = this.project_config.JsonPath + this.metaJson;
    return new Promise((resolve, reject) => {
      self.configServ.getJSON(url).subscribe(data => {
        this.setMetaData(data);
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for get prjectconfig
  * * */
  getConfig() {
    let self = this;
    let url = this._jsonURL;
    return new Promise((resolve, reject) => {
      self.configServ.getJSON(url).subscribe(data => {
        self.config = data[data["config"]];
        self.config['authentication'] = data['authentication'];
        this.baseURL = self.config.baseURL;
        this.project_config = self.config;
        resolve(self.config)
      }, error => reject(error));
    });
  }

  /* * *
  * method for set Project
  * * */
  setProjectConfig(projectCode) {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.config) {
        self.config['project'] = projectCode || this.DefaultProject;
        self.config['JsonPath'] = "../assets/" + self.config["project"] + "/";
        self.config['basePath'] = "../assets/" + self.config["project"] + "/assets/";
        this.project_config = self.config;
        this.projectName = self.config['project'];
        resolve(self.config);
      } else {
        this.getConfig().then(data => {
          if (!self.config) reject();
          self.config['project'] = projectCode || this.DefaultProject;
          self.config['JsonPath'] = "../assets/" + self.config["project"] + "/";
          self.config['basePath'] = "../assets/" + self.config["project"] + "/assets/";
          this.project_config = self.config;
          this.projectName = self.config['project'];
          resolve(self.config);
        }).catch(err => reject(err));
      }
    })
  }

  /* * *
  * set project
  * * */
  setProject(project) {
    if (this.config) {
      this.config['project'] = project || this.DefaultProject;
      this.config['JsonPath'] = "../assets/" + this.config["project"] + "/";
      this.config['basePath'] = "../assets/" + this.config["project"] + "/assets/";
      this.project_config = this.config;
      this.projectName = this.config['project'];
    }
  }

  /* * *
  * get project
  * * */
  getProjectConfig(project) {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.config && self.config.project == project) {
        resolve(self.config)
      } else {
        self.config['project'] = project || this.DefaultProject;
        self.config['JsonPath'] = "../assets/" + self.config["project"] + "/";
        self.config['basePath'] = "../assets/" + self.config["project"] + "/assets/";
        this.project_config = self.config;

        //** assign the project name value in projectName variable **/
        this.projectName = self.config['project'];

        resolve(self.config)
      }
    })
  }

  /* * *
  * get project Json
  * * */
  getProjectJson() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.configServ.getJSON(self.baseURL + 'ProjectData/').subscribe(data => {
        this.projectJson = data; //stored to project data
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * get profile Datas
  * * */
  getProfile() {
    let self = this;
    return new Promise((resolve, reject) => {
      self.configServ.getJSON(self.baseURL + 'profileData/').subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * * *
  * remove all elements call below apis
  * * */
  removeElements() {
    this.datauxview.clearAllResources();
  }

  /* * * *
  * Method for return mesh data
  * * */
  getModelConfigData() {
    return this.metaData['modelConfig'];
  }

  /* * * *
  * method for set meta data
  * * * */
  setModelObjectData() {
    this.modelObjectData = [];
    let meshData = this.getModelConfigData()
    meshData.forEach(mesh => {
      if (mesh.models.length > 0) {
        this.modelObjectData = this.modelObjectData.concat(mesh.models);
      }
    });
  }

  /* * * *
  * method for get model object data
  * * * */
  getModelObjectData() {
    return this.modelObjectData;
  }

  /* * *
  * method for create annotation
  * * */
  createAnnotation(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.annotationApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for fetch all annotation
  * * */
  getAnnotationData() {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.getData(this.project_config.annotationApi).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for fetch particular id based data
  * * */
  getAnnotation(id: string) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.getData(this.project_config.annotationApi + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for updtae annotation
  * * */
  updateAnnotation(id: string, data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.annotationApi + "/update/" + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for delete annotation
  * * */
  deleteAnnotation(id: string) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.deleteData(this.project_config.annotationApi + '/' + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for notes create 
   * * */
  createNotes(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.notesApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for fetch all notes data
   * * */
  getNotes() {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.getData(this.project_config.notesApi).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for update notes data
   * * */
  updateNotes(id: string, data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.notesApi + '/' + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for delete notes data
   * * */
  deleteNotes(id: string, data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.notesApi + '/delete/' + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for create tagshape data
   * * */
  createTagShape(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.tagShapeApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for update tagshape data
   * * */
  updateTagShape(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.tagShapeApi + '/tagShapeId', data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for fetch all notes data
   * * */
  getCollectionAnnotation(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.collectionAnnotationApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for create defects data
  * * */
  createDefects(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.defectsApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for fetch Defects data
   * * */
  getDefects(id: string) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.getData(this.project_config.defectsApi + '/' + id).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for update Defects data
   * * */
  updateDefects(id: string, data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.defectsApi + '/' + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for create Marker data
  * * */
  createMarker(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.markerApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for update Marker data
  * * */
  updateMarker(id: string, data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.putData(this.project_config.markerApi + '/' + id, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for create collection category
  * * */
  createCollectionType(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.collectionApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
   * method for fetch all collection category data
   * * */
  getCollectionCategory(venue) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.getData(this.project_config.collectionApi + '/' + venue).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for create group collection
  * * */
  createCollectionStatus(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.notesStatusApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for fetch all status collection
  * * */
  getStatusCollection(venue) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.getData(this.project_config.notesStatusApi + '/' + venue).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for create group collection
  * * */
  createCollectionGroup(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.notesGroupApi, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for fetch all group collection
  * * */
  getGroupCollection(venue) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.getData(this.project_config.notesGroupApi + '/' + venue).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for update component as visible
  * * */
  updateComponentAsVisible(name, visible, visibility = 'solid') {
    let meshObject = this.modelObjectData.filter(mesh => mesh['hasChildren'] == true);
    this.updateComponentVisible(meshObject, name, visible, visibility);
  }

  /* * * *
  * Method for update visible state
  * * */
  updateComponentVisible(meshObject, name, visible, visibility) {
    for (let mesh of meshObject) {
      if (mesh.name == name) {
        mesh['available'] = visible;
        mesh['visibility'] = visibility;
        break;
      }
      let meshChildren = mesh['children'];
      if (meshChildren.length > 0) {
        meshChildren.forEach(meshChild => {
          this.updateComponentVisible([meshChild], name, visible, visibility);
        });
      }
    };
  }

  /**
   * to check if a model is loaded via ondemad 
   * @param cname // model name
   * @returns 
   */
  isCheckOndemandComponent(cname) {
    let isOndemandComp = false;
    let modelConfig = this.getModelConfigData();
    for (let config of modelConfig) {
      let models = config.models || [];
      const isFound = models.some(model => model.name == cname);
      if (config.loadrule['start'] == 'ondemand' && isFound) {
        isOndemandComp = true;
        break;
      }
    }
    return isOndemandComp;
  }

  /* * * * * *
  * method excel upload
  * * * * * */
  uploadExcelData(formData, cbck) {
    let xhr = new XMLHttpRequest();
    let reponse = "";
    let url = this.baseURL + 'excelUpload';

    let accessToken = this.getAccessToken();
    let authentication = this.project_config['authentication'] || 'yes';
    let projectCode = this.project_config['project'] || 'common';

    /**
     * authorization - accesstoken 
     * authentication - whether its authenticate or / not
     * projectCode - select project code if no project selected it will pass 'common'
     */
    xhr.open('POST', url, true);
    xhr.setRequestHeader('authorization', "Bearer " + accessToken);
    xhr.setRequestHeader('authentication', authentication);
    xhr.setRequestHeader('projectcode', projectCode);

    xhr.onload = function (e) {
      reponse = xhr.responseText;
      cbck(reponse);
    };
    xhr.onreadystatechange = function (aEvt) {
      if (xhr.readyState == 4) {
        if (xhr.status == 200) {
          let response = { 'result': "Success" }
          cbck(response)
        } else {
          let response = { 'result': "FAIL" }
          cbck(JSON.stringify(response))
        }
      }
    };
    xhr.send(formData);
  }


  /* * *
   * method for get signed Request url
   * * */
  getSignedRequest(file) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.getData(this.project_config.sourceUpload + `?file-name=${file.name}&file-type=${file.type}`).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for upload source file
  * * */
  uploadSourceFile(file, signedRequest, cbck) {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', signedRequest);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          cbck({ success: true, message: 'file uploaded !' });
        }
        else {
          cbck({ success: false, message: 'file upload failed!' });
        }
      }
    };
    xhr.send(file);
  }

  /* * *
  * method for upload source file
  * * */
  fileUpload(formData) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postAPI(this.project_config.fileUpload, formData).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for upload source file
  * * */
  base64FileUpload(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postAPI(this.project_config.base64FileUpload, data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for delete upload files
  * * */
  deleteFile(data) {
    let self = this.configServ;
    return new Promise((resolve, reject) => {
      self.postData(this.project_config.fileUpload + 'delete', data).subscribe(data => {
        resolve(data);
      },
        error => reject(error)
      );
    })
  }
}



