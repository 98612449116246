import { Injectable } from '@angular/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class NetworkConnectivityService {
  networkStatus: any;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor() {
    this.checkNetworkStatus();
  }

  /* * *
   * interent check
   * * */
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.networkStatus = status ? 'online' : 'offline';
        console.log(" networkStatus ", this.networkStatus);
      });
  }

  /**
   * Unsubscribe all subscriptions to avoid memory leak
   */
  onDestroy() {
    this.networkStatus$.unsubscribe();
  }
}
