import { Injectable } from '@angular/core';
import { DbService } from '../DB/db.service';
import { DataServiceService } from '../Service/data-service.service';
import { CollectionManagerService } from '../Service/collection-manager.service';
import { BrodcastManagerService } from '../Service/brodcast-manager.service';
import { NetworkConnectivityService } from '../Service/network-connectivity.service';
import { getConfigService } from '../DB/getConfig';
@Injectable({
  providedIn: 'root'
})
export class SyncService {
  broadcastSubs: any; // communication service instance
  collectionType: any = []; //collections type data
  collectionCategoryList: any = []; // collections category list 
  uploadCollectionNotes: any = []; //local DB to move to live DB data
  uploadedAttchments: any = []; // live server attachements
  categoryIndex: any = 0;
  categoryCallback;

  constructor(
    private broadcastServ: BrodcastManagerService,
    private collectionServ: CollectionManagerService,
    private dbService: DbService,
    private dataServ: DataServiceService,
    private getConfigServ: getConfigService,
    public networkServ: NetworkConnectivityService,
  ) {
    this.initBroadcastEvent();
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.broadcastServ.getInstance().next(data);
  }

  /* *
  * initialize broadcast event
  * * */
  initBroadcastEvent() {
    this.broadcastSubs = this.broadcastServ.getInstance()
      .subscribe((data: any) => {

        if (data.src === "collection-manager") {
          if (data.event === 'on-save-defects-local-db') {
            let obj = data['data'];
            if (obj) {
              this.manageDefects(obj);
            }
          }
          if (data.event === 'on-delete-defects-local-db') {
            let obj = data['data'];
            if (obj['rowNumber']) {
              this.deleteDefect(obj['rowNumber']);
            }
          }
        }

      });
  }

  /**
   * 
   * =================================================
   *  project data download start region
   * =================================================
   * 
   */

  /* * *
  * method for sync project Data
  * */
  downloadProjectData() {
    return new Promise((resolve, reject) => {
      this.dataServ.getProjectJson().then((projectData: any) => {
        this.dbService.deleteAll('ProjectData').then(res => {
          this.dbService.bulkSaveData('ProjectData', projectData).then((response: any) => {
            resolve(response);
          }), error => reject(error)
        });
      }).catch((error) => { reject(error) });
    });
  }

  /* * *
  * method for sync profile Data
  * */
  downloadProfileData() {
    return new Promise((resolve, reject) => {
      this.dataServ.getProfile().then((profile_Json: any) => {
        this.dbService.deleteAll('ProfileData').then(res => {
          this.dbService.bulkSaveData('ProfileData', profile_Json).then((response: any) => {
            resolve(response);
          }).catch((error) => { reject(error) });
        }).catch((error) => { reject(error) });
      }).catch((error) => { reject(error) });
    });
  }

  /* * *
  * method for sync profile Data
  * */
  downloadCollectionType() {
    this.collectionType = [];
    return new Promise((resolve, reject) => {
      this.dbService.deleteAll('collectionType').then(res => {
        this.dbService.getData('ProjectData').then(projectData => {
          this.getAllCollectionType(projectData, 0, (collectionType) => {
            this.dbService.bulkSaveData('collectionType', collectionType).then((response: any) => {
              resolve(response);
            }).catch((error) => { reject(error) });
          });
        }).catch((error) => { reject(error) });
      }).catch((error) => { reject(error) });
    });
  }

  /* * *
  * method for get all collection types
  * */
  getAllCollectionType(projectData: any = [], dataIndex = 0, cbck) {
    if (projectData.length > dataIndex) {
      let project = projectData[dataIndex];
      this.dataServ.setProject(project.projectCode);
      this.dataServ.getCollectionCategory(project.projectCode).then((collectionTyps: any) => {
        this.collectionType = this.collectionType.concat(collectionTyps);
        dataIndex++;
        this.getAllCollectionType(projectData, dataIndex, cbck);
      }).catch(err => {
        dataIndex++;
        this.getAllCollectionType(projectData, dataIndex, cbck);
      });
    } else {
      this.dataServ.projectName = "";
      cbck(this.collectionType);
    }
  }

  /**
   * 
   * =================================================
   *  upload logic start region
   * =================================================
   * 
   */

  /* * *
  * method for sync upload/download collections data
  * */
  syncCollectionData() {
    if (this.networkServ.networkStatus == 'offline') {
      alert("please connect internet!");
    } else {

      this.collectionCategoryList = this.collectionServ.getAnnotationCategoryList();
      if (this.collectionCategoryList.length == 0) {
        alert("no data to process!");
        return;
      };

      this.broadcastInfo({ src: 'loader', event: 'syncLoading', message: "Uploading/Downloading Collections Data...", state: true });
      this.categoryIndex = 0;
      this.uploadCollectionData(() => {
        this.downloadCollectionData(() => {
          this.broadcastInfo({ src: 'loader', event: 'syncLoading', message: "Downloading Collections Data...", state: false });
          this.broadcastInfo({ src: 'sync-service', event: 'load-annotation-data', data: [] });
        });
      });

    }
  }

  /* * *
  * method for get local DB collections data
  * */
  uploadCollectionData(cbck) {
    this.categoryCallback = cbck;
    if (this.collectionCategoryList.length > this.categoryIndex) {

      this.uploadCollectionNotes = [];
      let colelctionType = this.collectionCategoryList[this.categoryIndex]['id'];
      let project = this.collectionCategoryList[this.categoryIndex]['venue'];

      this.dbService.getLocalCollectionNotes(project, colelctionType).then((collectionNotes) => {
        this.uploadCollectionNotes = collectionNotes;
        this.processUploadCollectionData();
      }).catch((error) => { });

    } else {
      console.log(" *********** category upload process completed !");
      this.categoryIndex = 0;
      cbck();
    }
  }

  /* * *
  * method for upload attachments
  * */
  uploadAttachments(uploadFiles = [], dataIndex = 0, cbck) {
    if (uploadFiles.length > dataIndex) {
      let file = uploadFiles[dataIndex];
      if (typeof file === 'string' && file.includes('base64')) {
        this.dataServ.base64FileUpload({ base64image: file }).then((response) => {
          if (response['success']) {
            let obj = { "fileName": response['filePath'], "description": "" }
            this.uploadedAttchments.push(obj);
          }
          dataIndex++;
          this.uploadAttachments(uploadFiles, dataIndex, cbck);
        });
      } else {
        dataIndex++;
        this.uploadedAttchments.push(file);
        this.uploadAttachments(uploadFiles, dataIndex, cbck);
      }
    } else {
      console.log(" *********** attachments upload completed !");
      cbck(this.uploadedAttchments);
    }
  }

  /* * *
  * method for upload collections data
  * */
  processUploadCollectionData(dataIndex = 0) {
    if (this.uploadCollectionNotes.length > dataIndex) {
      let data = this.uploadCollectionNotes[dataIndex];
      let uploadFiles = data['uploadFile'] || [];
      let typeCollectionId = data['_id'] || '';

      /**
       * upload file to server
       */
      this.uploadedAttchments = [];
      this.uploadAttachments(uploadFiles, 0, (uploadedAttchments) => {
        data['uploadFile'] = uploadedAttchments;
        data['defectsData']['uploadFile'] = uploadedAttchments;

        if (typeCollectionId.startsWith('localdb_')) {
          this.createCollectionNotes(dataIndex, data);
        } else {
          this.updateCollectionNotes(dataIndex, data);
        }
      });

    } else {
      this.categoryIndex++;
      console.log(" *********** data upload process completed !");
      this.uploadCollectionData(this.categoryCallback);
    }
  }

  /* * *
  * method for create collections data
  * */
  createCollectionNotes(dataIndex, objNotesData) {
    let defectsData = this.getConfigServ.getCreateDefectsParams(objNotesData['defectsData']);

    /* * *
     * create defects
     * * */
    this.dataServ.createDefects(defectsData).then(defectsResponse => {

      /* * *
       * create notes
       * * */
      objNotesData['typeCollectionId'] = defectsResponse['insertedId'];
      let notesParams = this.getConfigServ.getCreateNotesParams(objNotesData);
      this.dataServ.createNotes(notesParams).then(notesResponse => {

        /* * *
         * create tageshape
         * * */
        let pickedMeshObj = this.getConfigServ.getCreateNotesParams(objNotesData['tagPoints']);
        this.dataServ.createTagShape(pickedMeshObj).then(tagShape => {
          dataIndex++;
          this.processUploadCollectionData(dataIndex);
        }).catch((error) => {
          dataIndex++;
          this.processUploadCollectionData(dataIndex);
        });

      }).catch((error) => {
        dataIndex++;
        this.processUploadCollectionData(dataIndex);
      });

    }).catch((error) => {
      dataIndex++;
      this.processUploadCollectionData(dataIndex);
    });
  }

  /* * *
  * method for update collections data
  * */
  updateCollectionNotes(dataIndex, objNotesData) {
    let defectsData = this.getConfigServ.getUpdateDefectsParams(objNotesData, objNotesData['defectsData']);
    let defectsId = defectsData['_id'];

    /* * *
     * update defects
     * * */
    this.dataServ.updateDefects(defectsId, defectsData).then(defectsResponse => {

      /* * *
      * update notes
      * * */
      let notesId = objNotesData['_id'];
      let notesParams = this.getConfigServ.getUpdateNotesParams(objNotesData);
      this.dataServ.updateNotes(notesId, notesParams).then(notesResponse => {
        dataIndex++;
        this.processUploadCollectionData(dataIndex);
      }).catch((error) => {
        dataIndex++;
        this.processUploadCollectionData(dataIndex);
      });

    }).catch((error) => {
      dataIndex++;
      this.processUploadCollectionData(dataIndex);
    });

  }

  /**
   * 
   * =================================================
   *  download logic start region
   * =================================================
   * 
   */

  /* * *
  * method for download collections data
  * */
  downloadCollectionData(cbck) {
    this.broadcastInfo({ src: 'loader', event: 'showNonBlockLoading', state: true });
    this.dbService.deleteAll('collectionInfo').then(res => {
      this.dbService.deleteAll('collectionNotes').then(res => {
        this.processDownloadCollectionData(0, cbck);
      });
    });
  }

  /* * *
  * method for start downloading collections data
  * */
  processDownloadCollectionData(dataIndex = 0, cbck) {
    if (this.collectionCategoryList.length > dataIndex) {
      let params = this.getConfigServ.getCollectionParams(this.collectionCategoryList[dataIndex]);
      let project = params['venue'];

      this.dataServ.getCollectionAnnotation(params).then(collections => {
        let collectionInfo = Object.assign({}, collections['collectionData']['collectionInfo'], { venue: project });
        let collectionNotes = collections['collectionData']['collectionNotes'] || [];
        collectionNotes = collectionNotes.map(e => Object.assign(e, { venue: project }));
        this.downloadCollectionInfo(collectionInfo, (response) => {
          this.downloadCollectionNotes(collectionNotes, (response) => {
            dataIndex++;
            this.processDownloadCollectionData(dataIndex, cbck);
          });
        });
      }).catch((error) => { });
    } else {
      console.log("downloaded process completed !");
      cbck();
    }
  }

  /* * *
  * method for download collection info
  * */
  downloadCollectionInfo(collectionInfo, cbck) {
    this.dbService.bulkSaveData('collectionInfo', [collectionInfo]).then((response: any) => {
      cbck(response);
    }).catch((error) => { });
  }

  /* * *
  * method for download collection notes
  * */
  downloadCollectionNotes(collectionNotes, cbck) {
    this.dbService.bulkSaveData('collectionNotes', collectionNotes).then((response: any) => {
      cbck(response);
    }).catch((error) => { });
  }

  /**
   * 
   * =================================================
   *  download logic end region
   * =================================================
   * 
   */

  /* * *
  * method for manage defects data save/update
  * */
  manageDefects(annotateData) {
    if (annotateData["_id"]) {
      this.updateDefect(annotateData);
    } else {
      annotateData["_id"] = "localdb_" + Date.now().toString();
      this.createDefect(annotateData);
    };
  }

  /* * *
  * method for save new defects data
  * */
  createDefect(annotateData) {
    delete annotateData['rowNumber'];
    this.dbService.saveData('collectionNotes', annotateData).then((response: any) => {
      this.updateCreatedRecordId(response);
      this.broadcastInfo({ src: 'sync-service', event: 'close-defects-popup', data: [] });
    }).catch((error) => { });
  }

  /* * *
  * method for update new defects data
  * */
  updateDefect(annotateData) {
    this.dbService.deleteData('collectionNotes', annotateData['rowNumber']).then((response) => {
      delete annotateData['rowNumber'];
      this.dbService.saveData('collectionNotes', annotateData).then((response: any) => {
        this.updateCreatedRecordId(response);
        this.broadcastInfo({ src: 'sync-service', event: 'close-defects-popup', data: [] });
      }).catch((error) => { });
    }).catch((error) => { });
  }

  /* * *
  * method for update created record id
  * */
  updateCreatedRecordId(response) {
    this.collectionServ.annotateObj['_id'] = response['_id'];
    this.collectionServ.annotateObj['typeCollectionId'] = response['_id'];
    this.collectionServ.annotateObj['rowNumber'] = response['rowNumber'];
  }

  /* * *
  * method for delete defect 
  * */
  deleteDefect(rowNumber) {
    this.dbService.deleteData('collectionNotes', rowNumber).then((response) => {
      console.log('deleted!');
    });
  }

}
