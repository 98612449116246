<!--* Worspace Section Start *-->
<div class="workSpace mat-app-background" *ngIf="!navServ.renderVenue">
    <div class="container-88">
        <div class="display-flex margin-left-8">
            <p fxFlex="50" class="head2-text text-weight-600">DMMS Workspace</p>
        </div>

        <!-- grid view -->
        <div class="container">
            <div class="grid-row">
                <div *ngFor="let project of projectDataStruct" class="padding-bottom-8 margin-8">
                    <mat-card (click)="openProject($event,project)"
                        [ngClass]="project.thumbNailPath ? 'mata-data padding-0 text-center opacity-06 rounded-8 mat-card-list-background' : 'mat-card-list-background create-meta'">
                        <mat-icon *ngIf="!project.thumbNailPath" class="mata-content">{{project.matIcon}}</mat-icon>
                        <img [class.hover-hidden]="project.projectCode=='mepm'" *ngIf="project.thumbNailPath"
                            src="{{project.thumbNailPath}}">
                        <img *ngIf="project.projectCode=='mepm'" class="hover-view display-none"
                            src="../../../../assets/image/hfx-vessel-gif.gif">
                        <mat-icon *ngIf="project.thumbNailPath"
                            class="material-symbols-outlined">{{project.matIcon}}</mat-icon>

                        <!--* subMenu section start *-->
                        <mat-card-actions class="padding-8 text-center">
                            <p class="margin-top-4 margin-bottom-4">{{project.projectName}}</p>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item *ngFor="let menu of project.submenu">
                                    <span>{{menu}}</span>
                                </button>
                            </mat-menu>
                        </mat-card-actions>
                        <!--* subMenu section end *-->
                    </mat-card>
                </div>
            </div>
        </div>

    </div>
</div>
<!--* Worspace Section End *-->

<!--* *
* load System nav component
* *-->
<app-system-nav *ngIf="navServ.renderVenue"></app-system-nav>