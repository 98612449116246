import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/**
 * local DB instance
 */
import { NgxIndexedDBModule, DBConfig } from "ngx-indexed-db";
import { dbConfig } from './DB/DBConfig';
const config: DBConfig = dbConfig();

/**
 * app routing module 
 */
import { DfxmobileimModule } from './Components/dfxmobileim/dfxmobileim.module'
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './Module/shared/shared.module';

/**
 * Components 
 */
import { AppComponent } from './app.component';

/**
 * service 
 */
import { httpInterceptor } from './Service/http.interceptor';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DfxmobileimModule,
    NgxIndexedDBModule.forRoot(config),
    BrowserAnimationsModule,
    MatDialogModule
  ],
  providers: [
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
