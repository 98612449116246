<!--sidebar section start-->
<div class="sidebarSection">
    <!--sidebar menu section start-->
    <div class="sidebar">
        <!--sidebar menu label list section start-->
        <div class="sidebar-list mat-app-background"
            *ngIf="navServ.getNavBarPanelState('sideBar')&&navServ.selectedMenu.length>0">
            <div class="topSidebarList">
                <ng-template ngFor let-menu [ngForOf]="navServ.selectedMenu">
                    <ng-container *ngIf="menu.category == 'annotation'">
                        <div [class.activeNav]="navServ.getActiveNavPanel() == menu.id" class="panel_handle"
                            (click)="setNavPanel(menu)">
                            <span class="panel_handle_label head5-text text-weight-700">{{menu.name}}</span>
                        </div>
                    </ng-container>
                </ng-template>
            </div>
        </div>
        <!--sidebar menu label list section end-->

        <!--sidebar toggle section start-->
        <div class="sidebar-icon">
            <mat-icon class="mat-app-background reverse-theme" (click)="navServ.toggleNavBarPanel('sideBar')">
                {{navServ.getNavBarPanelState('sideBar')?'navigate_before':'menu'}}</mat-icon>
        </div>
        <!--sidebar toggle section end-->
    </div>
    <!--sidebar menu section end-->
    <!--mobile menu start-->
    <!-- <div class="mobile-sidebar-icon mobileView" *ngIf="!navServ.getNavBarPanelState('sideBar')">
        <button mat-mini-fab class="mat-app-background" (click)="navServ.toggleNavBarPanel('sideBar')">
            <mat-icon  >
            {{navServ.getNavBarPanelState('sideBar')?'':'menu'}}</mat-icon>
        </button>
    </div> -->
    <!--mobile menu end-->
    <!--sidebar panel section start-->
    <div class="sidebar-panel mat-app-transparent-background"
        [ngClass]="getSidepanelState('sidePanel')">
        <div class="sidebar-item">
            <!-- //Loaded Collection category viewer -->
            <app-collection-category-viewer *ngIf="getPanelState('collection')['show']"
                [ngClass]="getPanelState('collection')['class']" [collectionType]="navServ.getActiveNavPanel()"
                [selectedNavMenu]="navServ.getActiveNavMenu()">
            </app-collection-category-viewer>
        </div>
    </div>
    <!--sidebar panel section end-->
</div>
<!--sidebar section end-->

<!-- Moblie view bottom tab -->
<div class="navbar mat-app-background mobileView" *ngIf="navServ.getNavBarPanelState('sideBar')&&navServ.selectedMenu.length>0">
    <ng-template ngFor let-menu [ngForOf]="navServ.selectedMenu">
        <ng-container *ngIf="menu.category == 'annotation'">
            <div class="navList" [class.activeNav]="navServ.getActiveNavPanel() == menu.id" (click)="setNavPanel(menu)">
                <span  class="panel_handle_label head5-text text-weight-600">{{menu.name}}</span>
            </div>
        </ng-container>
    </ng-template>
</div>
<!-- Moblie view bottom tab -->