<div class="header-section container-100 margin-bottom-75 display-flex">
  <!-- header-left-side -->
  <div *ngIf="navServ.renderVenue" class="header-comp position-absolute float-left display-flex flex-wrap mat-header-background rounded-5">

    <!-- header-logo-container -->
    <!-- <div
      class="padding-4 padding-right-3 padding-left-3 header-image-container height-px-30 header-comp-child rounded-5"> -->
      <!-- header-logo   -->
      <!-- <img class="header-image height-px-30 rounded-2" src="../../../../assets/image/Navymarine.png" /> -->
      <!-- ends header-logo   -->
    <!-- </div> -->
    <!-- ends header-logo-container -->

    <div class="even-pading min-width-56 padding-left-16 padding-right-16">
      <div class="rounded-5 header-comp-left display-flex margin-top-4 margin-bottom-4 padding-right-4">
    <!-- mat icon button - home & right-arrow -->
    <button class="home-icon rounded-5 pointer hover" mat-button matTooltip="Home" matTooltipPosition="below"
          (click)="gotoHomeScreen()"><mat-icon
            class="padding-bottom-6 material-icons-outlined">dataset</mat-icon></button>
        <mat-icon
          class="metaverse-icon margin-left-12 margin-top-2 margin-bottom-2 padding-left-0 padding-right-8">keyboard_arrow_right</mat-icon>

    <!-- My Metaverse mat-button: only for desktop -->
    <button class="header-proj-button metaverse-container padding-left-12 rounded-5" mat-button
          [matMenuTriggerFor]="menu1" matTooltip="Project" matTooltipPosition="below">
          {{dataServ.selectedProjectData?dataServ.selectedProjectData.projectName:dataServ.projectName}}
          <mat-icon class="margin-left-12 arrowDown">keyboard_arrow_down</mat-icon>
        </button>
    <!-- ends My Metaverse mat-button -->

    <!-- My Metaverse mat-button: for ipad -->
    <button class="header-proj-icon metaverse-container padding-left-6 rounded-5" mat-icon-button
          [matMenuTriggerFor]="menu1" matTooltip="Project" matTooltipPosition="below">
          <mat-icon class="apps-icon">apps</mat-icon>&nbsp;&nbsp;
        </button>
    <!-- ends My Metaverse mat-button -->

    </div>
    <!-- mat menu for My Metaverse -->
    <mat-menu #menu1="matMenu" id="menu1" class="mat-menu-panel-border" xPosition="before">
        <ng-container *ngFor="let project of dataServ.projectJson">
          <button mat-menu-item class="nav-dropdown-menu paragraph-text padding-right-8 padding-left-16"
            *ngIf="project.projectCode != dataServ.projectName"
            (click)="renderProject(project.projectCode)">{{project.projectName}}</button>
        </ng-container>
      </mat-menu>
    <!-- ends mat menu for My Metaverse -->
    </div>
  </div>
  <!-- ends header-left-side -->

  <!-- header-right-side -->
  <div
    class="header-comp-right display-flex height-px-46 float-right position-absolute flex-wrap margin-bottom-2 rounded-5">
    <ng-container *ngIf="navServ.showHeader">
      <!-- mat button for Collaboration -->
      <div class="header-design margin-right-8 height-px-38 rounded-4">
        <button
          class="header-collaboration-button header-comp-child even-pading min-width-56 padding-left-16 padding-right-16"
          mat-button [matMenuTriggerFor]="menu2" *ngIf="navServ.showHeader" matTooltip="Collaboration"
          matTooltipPosition="below" [disabled]="!navbarDetails.collaborationMenuState">CGP <mat-icon
            class="margin-left-8">keyboard_arrow_down</mat-icon></button>
        <button
          class="header-collaboration-icon header-comp-child even-pading min-width-56 padding-left-16 padding-right-16 margin-bottom-2 rounded-4"
          mat-icon-button [matMenuTriggerFor]="menu2" *ngIf="navServ.showHeader" matTooltip="Collaboration"
          matTooltipPosition="below"
          [disabled]="!navbarDetails.collaborationMenuState"><mat-icon>groups</mat-icon>&nbsp;&nbsp;</button>
      </div>
      <!-- ends mat button for Collaboration -->

      <!-- mat menu for Collaboration -->
      <mat-menu #menu2="matMenu" class="mat-menu-panel-border" xPosition="before">
        <button mat-menu-item class="nav-dropdown-menu paragraph-text padding-right-8 padding-left-16"
          *ngFor="let collaboration of navbarDetails.collaboration">{{collaboration}}</button>
      </mat-menu>
      <!-- ends mat menu for Collaboration -->

      <!-- mat icon button - settings -->
      <div class="header-design margin-right-8 height-px-38 rounded-4">
        <button [disabled]="!navbarDetails.settingsMenuState" [class.hover]="navbarDetails.settingsMenuState"
          class="header-comp-child even-pading min-width-56 padding-left-16 padding-right-16  margin-bottom-2 rounded-4"
          mat-icon-button [matMenuTriggerFor]="menu3" *ngIf="navbarDetails.settings" matTooltip="Settings"
          matTooltipPosition="below">
          <mat-icon>settings</mat-icon>
        </button>
      </div>
      <!-- ends mat icon button - settings -->

      <!-- mat slide toggle for settings -->
      <mat-menu #menu3="matMenu" class="mat-expansion-panel mat-menu-panel-border" xPosition="before">
        <ng-template ngFor let-menu [ngForOf]="navbarDetails.settings">
          <button mat-menu-item class="pointer-none nav-dropdown-menu paragraph-text padding-right-8 padding-left-16"
            (click)="$event.stopPropagation();" [disabled]="menu.disabled">
            <span class="display-block">{{menu.type}}<small class="float-right margin-right-3 padding-left-5">
                <mat-slide-toggle class="pointer-auto margin-left-5 flot-right" [disabled]="menu.disabled"
                  (click)="selectSetting(menu)"></mat-slide-toggle></small></span>
          </button>
        </ng-template>
      </mat-menu>
      <!-- ends mat slide toggle for settings -->
    </ng-container>

    <div class="header-design margin-right-8 height-px-38 rounded-4">
      <div class="justify-content-end display-flex">
        <!-- mat icon button - notifications -->
        <div *ngIf="navServ.showHeader">
          <button
            class="header-comp-child notifications even-pading min-width-56 padding-left-16 padding-right-16 margin-bottom-2 rounded-4"
            (click)="notificationPanelShow()" mat-icon-button *ngIf="navbarDetails.notificationIcon"
            matTooltip="Notifications" matTooltipPosition="below" [class.hover]="navbarDetails.notificationMenuState"
            [disabled]="!navbarDetails.notificationMenuState">
            <mat-icon>notifications</mat-icon>
          </button>
        </div>
        <!-- ends mat icon button - notifications -->
        <!-- image profile -->
        <div>
          <button mat-button [matMenuTriggerFor]="menu"
            class="profile-button min-width-inherit padding-left-0 padding-right-2" matTooltip="Profile"
            matTooltipPosition="below">
            <img class="padding-3 border-2 border-solid text-color-blue rounded-8 margin-bottom-2 equal-width-24"
              src="../../../../assets/image/profile.png"></button>
          <mat-menu #menu="matMenu" class="mat-menu-panel-profile">
            <p
              class="border-bottom-solid margin-0 margin-left-18 margin-right-18 padding-8 border-bottom-1 text-color-white">
              Datifex Demo Account<small class="margin-left-4 text-color-lightgrey description-text">Admin</small></p>
            <div>
              <button mat-menu-item class="text-color-grey" disabled>
                <mat-icon class="mat-icon-20 text-color-grey">account_circle</mat-icon> My account
              </button>
              <button mat-menu-item class="text-color-grey" (click)="logout()">
                <mat-icon class="mat-icon-20 text-color-grey">logout</mat-icon> Logout
              </button>
            </div>
          </mat-menu>
        </div>
        <!-- ends image profile -->
      </div>
    </div>
  </div>
  <!-- ends header-right-side -->
</div>