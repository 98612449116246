<!--* *
* load header component
* *-->
<app-header  *ngIf="!navServ.showSyncScreen"></app-header>

<!--* *
* load workspace component
* *-->
<app-work-space *ngIf="!navServ.showSyncScreen"></app-work-space>

<!--* *
* load sync component
* *-->
<app-sync *ngIf="navServ.showSyncScreen"></app-sync>

