/**        
 * User: Arifa M
 * Date: 24/11/2022
 * Time: 10:00 AM
 * Purpose : The "ConfigService" manages the API call-relevant actions.       
 */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: any;
  private componentCntls: any;
  public DefaultProject = 'mepm';
  private projectDataStruct = [];
  private MetaverseDataStruct = [];
  private accessToken: any; //it holds accessToken
  private refershToken: any; //it holds refershToken

  constructor(private http: HttpClient) { }

  /* * *
  * method for set accesstoken/refershtoken
  * * */
  setToken(data) {
    this.accessToken = data['accessToken'];
    this.refershToken = data['refershToken'];
  }

  /* * *
  * method for get access token
  * * */
  getAccessToken() {
    return this.accessToken;
  }

  /* * *
  * method for get refersh token
  * * */
  getRefershToken() {
    return this.refershToken;
  }

  /* * *
  * get json data
  * * */
  getJSON(url = null): Observable<any> {
    return this.http.get(url);
  }

  /* * *
  * get api
  * * */
  getData(url): Observable<any> {
    return this.http.get(url);
  }

  /* * *
  * post api without stringify
  * * */
  postAPI(url, data): Observable<any> {
    return this.http.post(url, data);
  }

  /* * *
  * post api
  * * */
  postData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(url, body, { 'headers': headers })
  }

  /* * *
  * put api
  * * */
  putData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.put(url, data, { 'headers': headers })
  }

  /* * *
  * delete api
  * * */
  deleteData(url: string): Observable<any> {
    return this.http.delete(url);
  }

  /* * *
* get Metaverse Datas
* * */
  getMetaverse() {
    let self = this;
    let url = "../assets/DBJson/header.json"
    return new Promise((resolve, reject) => {
      if (self.config) {
        resolve(self.config)
      } else {
        self.getJSON(url).subscribe(data => {
          this.MetaverseDataStruct = data;
          resolve(data)
        },
          error => reject(error)
        );
      }
    })
  }
}
