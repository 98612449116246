import { Injectable } from '@angular/core';
import { DataServiceService } from 'src/app/Service/data-service.service';

@Injectable({
  providedIn: 'root'
})
export class getConfigService {

  constructor(private dataServ: DataServiceService){

  }

  /* * *
  * method generate params for colelctions data
  * */
  getCollectionParams(obj = {}) {
    return {
      "date": [],
      "vessel": [],
      "type": [obj['id']] || "",
      "venue": obj['venue'] || ""
    };
  }

 /* * * * *
  * get unique number
  * * * * * */
  getUIDData() {
    return Math.floor(100000 + Math.random() * 900000).toString();
  }

  /* * *
  * method generate params for create defects data
  * */
  getCreateDefectsParams(obj = {}) {
    return {
      "status": obj['status'] || {},
      "group": obj['group'] || {},
      "statusId": obj['statusId'] || '',
      "uploadFile": obj['uploadFile'] || [],
      "description": obj['description'] || "",
      "startDate": obj['startDate'] || "",
      "userList": obj['userList'] || [],
      "materialNo": obj['materialNo'] || "",
      "surveyNo": obj['surveyNo'] || "",
      "pmrsNo": obj['pmrsNo'] || "",
      "componentId": obj['componentId'] || "",
      "notificationNo": this.getUIDData(),
      "venue": this.dataServ.projectName,
    };
  }

  /* * *
  * method generate params for update defects data
  * */
  getUpdateDefectsParams(notes = {}, obj = {}) {
    return {
      "_id": notes['typeCollectionId'] || "",
      "status": obj['status'] || {},
      "group": obj['group'] || {},
      "statusId": obj['statusId'] || '',
      "uploadFile": obj['uploadFile'] || [],
      "description": obj['description'] || "",
      "startDate": obj['startDate'] || "",
      "userList": obj['userList'] || [],
      "materialNo": obj['materialNo'] || "",
      "surveyNo": obj['surveyNo'] || "",
      "pmrsNo": obj['pmrsNo'] || "",
      "componentId": obj['componentId'] || ""
    };
  }

  /* * *
  * method generate params for notes data
  * */
  getCreateNotesParams(obj = {}) {
    return {
      "tagShapeId": obj['tagShapeId'] || "",
      "startDate": obj['startDate'] || "",
      "typeCollectionId": obj['typeCollectionId'] || "",
      "typeCollection": obj['typeCollection'] || "",
      "parentMeshId": obj['parentMeshId'] || "",
      "shapeId": obj['shapeId'] || "",
      "props": obj['props'] || {},
      "tagName": obj['tagName'] || "",
      "description": obj['description'] || "",
      "formStatus": obj['formStatus'] || "",
      "status": obj['status'] || {},
      "annotationId": obj['annotationId'] || "",
      "markerId": obj['markerId'] || "",
      "componentId": obj['componentId'] || "",
      "group": obj['group'] || {},
      "uploadFile": obj['uploadFile'] || [],
    };
  }

  /* * *
  * method generate params for notes data
  * */
  getUpdateNotesParams(obj = {}) {
    return {
      "_id": obj['_id'] || "",
      "collectionRefId": obj['collectionRefId'] || "",
      "statusId": obj['statusId'] || "",
      "tagShapeId": obj['tagShapeId'] || "",
      "startDate": obj['startDate'] || "",
      "typeCollectionId": obj['typeCollectionId'] || "",
      "typeCollection": obj['typeCollection'] || "",
      "parentMeshId": obj['parentMeshId'] || "",
      "shapeId": obj['shapeId'] || "",
      "props": obj['props'] || {},
      "tagName": obj['tagName'] || "",
      "description": obj['description'] || "",
      "formStatus": obj['formStatus'] || "",
      "status": obj['status'] || {},
      "annotationId": obj['annotationId'] || "",
      "markerId": obj['markerId'] || "",
      "componentId": obj['componentId'] || "",
      "group": obj['group'] || {},
      "uploadFile": obj['uploadFile'] || [],
    };
  }

  /* * *
  * method generate params for notes data
  * */
  getTageShapeParams(obj = {}) {
    return {
      "tagShapeId": obj['tagShapeId'] || "",
      "shapeId": "sphere",
      "position": obj['position'] || {
        "x": 0,
        "y": 0,
        "z": 0,
      },
      "parentMeshId": obj['parentMeshId'] || "",
      'markerId': obj['markerId'] || "",
      "venue": obj['venue'] || ""
    };
  }


}
