import { Component, Inject, Renderer2, HostListener} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  darkMode: boolean = true; // Dark mode state
  constructor( @Inject(DOCUMENT) private document: Document, private domSanitizer: DomSanitizer,private matIconRegistry: MatIconRegistry, private render: Renderer2){
    this.themeChange({ checked: this.darkMode });
        //custom icon register and used
        this.matIconRegistry.addSvgIcon(
          `2d-icon`,
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/image/2d-icon.svg")
        );
        this.matIconRegistry.addSvgIcon(
          `3d-icon`,
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/image/3d-icon.svg")
        );
        this.matIconRegistry.addSvgIcon(
          `cube`,
          this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/image/cube.svg")
        );
  }
    /***
   * Theme change toggle
   */
    themeChange(e:any) {
      this.darkMode = e.checked;
      const hostClass = this.darkMode ? 'darkMode mat-app-background' : 'lightMode';
      this.render.setAttribute(this.document.body, 'class', hostClass)
    }
}
