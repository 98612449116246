import { Component } from '@angular/core';
import { SyncService } from '../../../DB/sync.service';
import { NavigationService } from '../../../Service/navigation.service';
import { BrodcastManagerService } from '../../../Service/brodcast-manager.service';
@Component({
  selector: 'app-sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.scss']
})
export class SyncComponent {

  constructor(private broadcastServ: BrodcastManagerService, public navServ: NavigationService, private syncServ: SyncService) {
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.broadcastServ.getInstance().next(data);
  }

  /* * *
  * method for sync profile Data
  * */
  syncProjectData() {
    if (this.syncServ.networkServ.networkStatus == 'offline') {
      alert("please connect internet!");
    } else {
      this.broadcastInfo({ src: 'loader', event: 'syncLoading', message: "Downloading Project Data...", state: true });
      this.syncServ.downloadProjectData().then(projectData => {
        this.syncServ.downloadProfileData().then(profile => {
          this.syncServ.downloadCollectionType().then(collectionType => {
            this.navServ.showSyncScreen = false;
            this.broadcastInfo({ src: 'loader', event: 'syncLoading', message: "Downloading Project Data...", state: false });
          });
        });
      });
    }
  }

}
