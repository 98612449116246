/**        
 * User: Arifa M
 * Date: 09/02/2023
 * Time: 11:50 AM
 * Purpose : The "Collection Manager Service" manages all the collections based activities.       
 */

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DataServiceService } from './data-service.service';
import { BrodcastManagerService } from './brodcast-manager.service';
import { NavigationService } from './navigation.service';

const noFindIdx = -1 // -1 means there is no data index
const spliceItem = 1;
@Injectable({
  providedIn: 'root'
})

export class CollectionManagerService {
  objNotesData: any = {};
  pickedMeshObj: any = {};
  selectedCollection: any = {};
  collectionAnnotationData: any = {}; //current selected Annotation data
  selectedCollectionNotesData: any = []; //current selected Annotation notes data
  tagLocation: any = {} // it holds picked mesh location
  tagMeshLocationState: boolean = false; // maintain whether pick location or normal state
  markerId: any = ""; //its holding marker it
  annotateObj: any = {};//storing locally handled data
  markerObj: any = {};//stored marker data
  isNewMarker: boolean = false; //on save checking whether its new/update
  componentId: any = ""; // on pick location if its on demand component means save it to notes 
  parentMeshId: any = ""; // on picked parent mesh id it contains
  formData = new FormData();
  collectionDataList: any = [] //store collection data
  collectionCategory: any = [];

  constructor(private navServ: NavigationService, private datePipe: DatePipe, public dataServ: DataServiceService, private broadcastServ: BrodcastManagerService) { }

  /* * * * *
   * method for communicate event instance with data to access all components
   * * * * * */
  broadcastInfo(data: any) {
    this.broadcastServ.getInstance().next(data);
  }

  /* * *
   * method for set selected collection category list data
   * * */
  setCollectionCategoryList(collectionList) {
    this.collectionCategory = collectionList;
    this.navServ.menuData['user'] = [];
    this.assignNavPanel(collectionList)
  }

  /* * *
   * method for set nav panel menu data
   * * */
  assignNavPanel(collectionList) {
    this.navServ.menuData['user'] = this.navServ.menuData['user'].concat(collectionList);
    this.navServ.setCollectionList();
  }

  /* * *
  * method for get selected collection category list data
  * * */
  getCollectionCategoryList() {
    return this.collectionCategory;
  }

  /* * *
  * method for annotation categoryList
  * * */
  getAnnotationCategoryList() {
    return this.collectionCategory.filter(e => e.category == 'annotation');
  }

  /* * *
  * method for set selected collection annotation notes data
  * * */
  setSelectedCollectionNotesData(notesData) {
    this.selectedCollectionNotesData = notesData.reverse() || [];
  }

  /* * *
  * method for set selected collection annotation notes data
  * * */
  getSelectedCollectionNotesData() {
    return this.selectedCollectionNotesData;
  }

  /* * *
  * method for set selected collection
  * * */
  setSelectedCollection(collection) {
    this.selectedCollection = collection;
  }

  /* * *
  * method for return selected collection
  * * */
  getSelectedCollection() {
    return this.selectedCollection;
  }

  /* * *
  * method for set pick location selection
  * * */
  setPickLocationState(boo) {
    this.tagMeshLocationState = boo;
  }

  /* * *
  * The manageCollectionNotes function controls newly created or updated collection notes.
  * * */
  manageCollectionNotes(postData, boo) {
    this.annotateObj = {};
    this.objNotesData = {};
    this.pickedMeshObj = this.getPickedMeshObj(postData);
    this.markerObj = this.constructMarkerData(postData);
    if (boo) {
      this.constructNotesData(postData);
    } else {
      this.constructUpdateNotesData(postData);
    }
  }

  /* * *
  * The post new notes data were created using the constructNotesData method.
  * * */
  constructNotesData(postData) {
    var props = {
      "location": '',
      "Vessel": '',
      "activity": postData.group['name'],
    }
    this.objNotesData['tagShapeId'] = this.pickedMeshObj.tagShapeId ? this.pickedMeshObj.tagShapeId : '';
    this.objNotesData['startDate'] = this.datePipe.transform(postData.filterDate, 'dd-MMM-YYYY, h:mm a');
    this.objNotesData['typeCollectionId'] = postData.collectionId;
    this.objNotesData['typeCollection'] = postData.typeCollection;
    this.objNotesData['parentMeshId'] = this.parentMeshId || postData.parentMeshId || "";
    this.objNotesData['shapeId'] = postData.selectedCollection['annotationType'];
    this.objNotesData['props'] = props;
    this.objNotesData['tagName'] = postData.group['name'] || "";
    this.objNotesData['description'] = postData.description || "";
    this.objNotesData['formStatus'] = postData.status ? postData.status.name : '';
    this.objNotesData['status'] = postData.status || {};
    this.objNotesData['annotationId'] = postData.selectedCollection['annotationId'];
    this.objNotesData['markerId'] = this.markerObj.markerId || this.markerId;
    this.objNotesData['componentId'] = this.componentId || postData.componentId;
    this.objNotesData['group'] = postData.group || {};
    this.objNotesData['uploadFile'] = postData.uploadFile || [];
    this.objNotesData['rowNumber'] = postData.rowNumber || '';
    this.objNotesData['defectsData'] = postData.defectsData || {};
    this.constructAnnotateList();
  }

  /* * *
  * method for get PickedMeshObj
  * * */
  getPickedMeshObj(postData) {
    let tagPoints = postData.tagPoints ? postData.tagPoints.position : { x: 0, y: 0, z: 0 };
    let tagLocation = this.isEmpty(this.tagLocation) ? this.tagLocation : tagPoints
    let pickedMeshObj = {
      "tagShapeId": postData.tagShapeId ? postData.tagShapeId : this.getUIDData(),
      "shapeId": "sphere",
      "position": tagLocation || {
        "x": 0,
        "y": 0,
        "z": 0,
      },
      "parentMeshId": this.parentMeshId || postData.parentMeshId || "",
      'markerId': postData.markerId || this.markerId,
      "venue": this.dataServ.projectName
    }
    return pickedMeshObj;
  }

  constructMarkerData(postData) {
    let markerId = postData.markerId || '';
    let markerData = {};
    this.isNewMarker = postData.markerId ? false : true;
    let marker = [];
    if (marker.length > 0) {
      let markerIdx = marker.findIndex(marker => marker.uid == this.markerId);
      if (markerIdx > -1) {
        markerData = {
          "position": marker[markerIdx].path || [],
          "parentMeshId": marker[markerIdx].pid || '',
          "color": marker[markerIdx].color || "",
          "markerId": markerId || marker[markerIdx].uid,
          "cutoff": marker[markerIdx].cutoff || "",
          "cutplane": marker[markerIdx].cutplane || "",
          "mwidth": marker[markerIdx].mwidth || "",
          "fill": marker[markerIdx].fill || "",
          "inorm": marker[markerIdx].inorm || '',
          "parentElementId": marker[markerIdx].parentElementId || ''
        }
        this.markerId = '';
      }
    } else if (postData.marker && postData.marker.length > 0 && this.markerId == "") {
      markerData = postData.marker[0];
    }
    return markerData;
  }

  /* * *
  * The post exist notes data were created using the constructNotesData method.
  * * */
  constructUpdateNotesData(postData) {
    let updateNotesData = {
      collectionRefId: postData.collectionRefId,
      description: postData.description || '',
      annotationId: postData.annotationId,
      markerId: this.markerObj.markerId || this.markerId,
      startDate: postData.startDate,
      status: postData.status || {},
      group: postData.group || {},
      statusId: postData.statusId || "",
      tagShapeId: postData.tagShapeId || "",
      _id: postData._id,
      typeCollectionId: postData.typeCollectionId,
      typeCollection: postData['typeCollection'],
      parentMeshId: postData.parentMeshId || this.parentMeshId || "",
      shapeId: postData.ShapeId || "",
      props: {
        "location": '',
        "Vessel": '',
        "activity": postData.group['name'],
      } || postData.props,
      tagName: postData.group['name'] || postData.tagName,
      componentId: postData.componentId || "",
      formStatus: postData.status ? postData.status.name : '',
      uploadFile: postData.uploadFile || [],
      rowNumber: postData['rowNumber'] || "",
      defectsData: postData.defectsData || {}
    }
    this.objNotesData = updateNotesData;
    this.constructAnnotateList();
  }

  /* * *
  * method for annotate data store the @param annotateObj
  * * */
  constructAnnotateList() {
    this.annotateObj = this.clone(this.objNotesData);
    this.annotateObj['tagPoints'] = this.pickedMeshObj;
    this.annotateObj['marker'] = [];
    if (this.isEmpty(this.markerObj)) {
      this.annotateObj['marker'].push(this.markerObj);
    }
    this.setAnnotateList(this.annotateObj);
  }

  /* * *
  * method for annotate data not change
  * * */
  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  /* * *
  * method for check object empty or not
  * * */
  isEmpty(obj) {
    const emptyObj = 0;
    return Object.keys(obj).length != emptyObj;
  }

  /* * *
  * method for store locally stored annotate data
  * * */
  setAnnotateList(annotateData) {
    if (annotateData['_id']) {
      let annotateIdx = this.selectedCollectionNotesData.findIndex(note => note._id == annotateData['_id']);
      if (annotateIdx != noFindIdx) {
        this.selectedCollectionNotesData.splice(annotateIdx, spliceItem);
      }
    }
    this.selectedCollectionNotesData.unshift(annotateData);
    let obj = this.getLocalDBCOnfig(annotateData);
    this.broadcastInfo({ src: 'collection-manager', event: 'on-save-defects-local-db', data: obj });
  }

  /* * *
  * method for store local DB data construction
  * * */
  getLocalDBCOnfig(obj) {
    return {
      "shapeId": obj.shapeId || "defects",
      "group": obj.group || {},
      "status": obj.status || {},
      "annotationName": this.selectedCollection['annotationName'] || "Defects",
      "tagPoints": obj.tagPoints || {},
      "marker": obj.marker || [],
      "_id": obj['_id'] || "",
      "tagShapeId": obj['tagShapeId'] || "",
      "description": obj['description'] || "",
      "markerId": obj['markerId'] || "",
      "parentMeshId": obj['parentMeshId'] || "",
      "collectionRefId": "",
      "annotationId": obj['annotationId'] || "",
      "startDate": obj['startDate'] || "",
      "endDate": obj['startDate'] || "",
      "startTime": "",
      "endTime": "",
      "uploadFile": obj['uploadFile'] || [],
      "userList": [],
      "typeCollectionId": obj['typeCollectionId'] || "",
      "typeCollection": obj['typeCollection'] || "",
      "props": obj['props'] || {},
      "tagName": obj['tagName'] || "",
      "componentId": "",
      "meshId": obj['meshId'] || "",
      "formStatus": obj['formStatus'] || "",
      "venue": this.dataServ.project_config['project'] || "",
      "rowNumber": obj['rowNumber'] || "",
      "localDB": true,
      "defectsData": obj['defectsData'] || {}
    };
  }

  /* * *
  * method for start to api calling
  * * */
  postApiCall() {
    if (this.objNotesData['_id']) {
      this.updateNotesData(this.objNotesData['_id'], this.objNotesData);
    } else {
      this.createNotes();
    }
  }

  /* * *
  * createNotes  method for calling creation notes api
  * * */
  createNotes() {
    this.dataServ.createNotes(this.objNotesData).then(response => {
      this.annotateObj['_id'] = response['insertedId'];
      this.componentId = '';
      this.parentMeshId = '';
      if (this.isEmpty(this.markerObj)) {
        this.createMarker();
      }
      this.createTagShape();
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  /* * *
  * method for calling create tagshape api
  * * */
  createTagShape() {
    if (this.pickedMeshObj) {
      this.dataServ.createTagShape(this.pickedMeshObj).then(tagShape => {
        this.tagLocation = {};
      }).catch((error) => {
        console.log("error ", error);
      });
    }
  }

  /* * *
  * method for calling update notes api
  * * */
  updateNotesData(id, updateData) {
    this.dataServ.updateNotes(id, updateData).then(saveNotesData => {
      this.componentId = '';
      this.parentMeshId = '';
      if (this.isNewMarker && this.isEmpty(this.markerObj)) {
        this.createMarker();
      } else if (this.isEmpty(this.markerObj)) {
        this.updateMarker();
      }
      this.updateTagShape();
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  /* * *
  * method for calling update tagshape api
  * * */
  updateTagShape() {
    if (this.pickedMeshObj) {
      this.dataServ.updateTagShape(this.pickedMeshObj).then(tagShape => {
        this.tagLocation = {};
      }).catch((error) => {
        console.log("error ", error);
      });
    }
  }

  /* * *
  * createMarker  method for calling creation marker api
  * * */
  createMarker() {
    this.dataServ.createMarker(this.markerObj).then(marker => {
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  /* * *
  * updateMarker method for update the marker 
  * * */
  updateMarker() {
    this.dataServ.updateMarker(this.objNotesData.markerId, this.markerObj).then(marker => {
    }).catch((error) => {
      console.log("error ", error);
    });
  }

  /* * *
  * method for random Id generation
  * * */
  getUIDData() {
    return Math.random().toString(36).substr(2, 9);
  }

  /* * *
  * method for delete Annotate data
  * * */
  deleteAnnotate(annotateData) {
    let annotateIdx = this.selectedCollectionNotesData.findIndex(annotate => annotate._id == annotateData['_id']);
    if (annotateIdx != noFindIdx) {
      let tagShapeId = annotateData.tagShapeId || "";
      this.selectedCollectionNotesData.splice(annotateIdx, spliceItem);

      this.broadcastInfo({ src: 'collection-manager', event: 'on-delete-defects-local-db', data: annotateData });
      // this.dataServ.deleteNotes(annotateData['_id'], annotateData).then(response => {
      // }).catch((error) => {
      //   console.log("error ", error);
      // });
    }
  }

  /* * *
  * method for reset the all the stored params
  * * */
  resetCollection() {
    this.selectedCollection = {};
    this.objNotesData = [];
    this.objNotesData = {};
    this.pickedMeshObj = {};
    this.selectedCollection = {};
    this.collectionAnnotationData = {};
    this.selectedCollectionNotesData = [];
    this.tagLocation = {}
    this.annotateObj = {};
    this.markerObj = {}
    this.componentId = '';
  }

  /* * *
  * method for excel upload api calling
  * * */
  excelUpload(ext, file) {
    let selectedCollection = this.getSelectedCollection();
    if (ext == 'xlsx' || ext == 'xls' || ext == 'xlsm' || ext == 'csv') {
      this.formData.append('file', file[0]);
      this.formData.append('selectedCollection', JSON.stringify(selectedCollection));
      this.formData.append('venue', this.dataServ.projectName);
      this.dataServ.uploadExcelData(this.formData, (uploadResp) => {
        if (uploadResp.result == 'Success') {
          this.broadcastInfo({ src: 'getCollection', event: 'collectonApi' });
        }
      });
    }
  }

  /* * *
  * method for set collection list 
  * * */
  setCollectionData(collectionData) {
    this.collectionDataList = collectionData;
  }

  /* * *
  * method for get collection list 
  * * */
  getCollectionData() {
    return this.collectionDataList;
  }
}
