/**        
 * User: Prabha.R
 * Date: 24/12/2022
 * Time: 10:00 AM
 * Purpose : The "NavigationService" manages the naactiveNavPanel       
 */
import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
/***
  * menu data interface define
  */
export interface ImenuData {
  user: any;
  admin: any;
}
/***
 * navigation bar interface define
 */
export interface InavBar {
  "sideBar": boolean,
  "sidePanel": boolean,
  "settingsPanel": boolean,
  "adminMode": boolean,
  "sideTabIndex": number,
  "filterPanel": boolean
}
@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  loginState: boolean = false;// it maintains login state
  loginData: any; // logged data assign
  public showHeader: boolean = false;//header show/hide toggle
  renderVenue: boolean = false; //toggle of venue show and hide
  isNotification: boolean = false; // Notification panel show / hide
  navbarDetails: any; // Metaverse Details Data
  showSidePanel: boolean = false;
  showSyncScreen: boolean = false; // whether sync screen show/not
  
  /**
   * @param user: menu list access based id,name list of data for user
   * @param admin: menu list access based id,name list of data for admin
   */
  menuData: ImenuData = {
    "user": [],
    "admin": [{ id: 'annotation', name: 'Annotation', category: 'admin' }]
  }; // Menu list
  selectedMenu: any = [];
  collectionList: any = [];
  /* * 
   * cutdraw_mode: show / hide toggle variable for 
   * CutDrawTool Component
   * * */
  cutdraw_mode = null;

  /* * 
   * cube_icons_mode: show / hide toggle variable for 
   * cube icons
   * * */
  cube_icons_mode = null;

  /* *
   * navBarConfig define all panels configurations here ,
   * @param sideBar: side bar toggle show/ hide sidebar boolean.
   * @param sidePanel: side panel show/hide boolean value toggle.
   * @param settingsPanel: setting panel show/hide boolean value toggle.
   * @param sideTabIndex: side tab index panel active state numder.
   * * */
  private navBarConfig: InavBar = {
    "sideBar": false,
    "sidePanel": false,
    "settingsPanel": false,
    "adminMode": true,
    "sideTabIndex": 0,
    "filterPanel": false
  };

  /* *
   * panelShowHideConfig define all panels configurations here ,
   * here is the definition for whether its destroy/visible hide
   * showhiderule  there are true rues
   * hidden - just visibility hide
   * none - complemente hide
   * * */
  private panelShowHideConfig = {
    "layer": { showhiderule: 'hidden' },
    "annotation": { showhiderule: 'none' },
    "defects": { showhiderule: 'hidden' },
    "workpackage": { showhiderule: 'hidden' },
  }

  public selectedNav: any = ""; // it holds selected nav panel code
  public selectedNavMenu: any = ""; // it holds selected nav panel code

  constructor() { }

  /* * *
  * setter nav bar state
  * Param 1: should pass navbarconfig key ex : sideBar
  * Param 2: should pass boolean state show/hide
  * * */
  setNavBarPanelConfig(navBarkey: string, boo: boolean) {
    this.navBarConfig[navBarkey] = boo;
  }

  /* * *
  * method for get all nav bar configration states 
  * * */
  getNavBarConfig() {
    return this.navBarConfig;
  }

  /* * *
  * method for get all nav bar configration states 
  * * */
  toggleNavBarPanel(navBarkey: string) {
    this.navBarConfig[navBarkey] = !this.navBarConfig[navBarkey];
    this.checkPanelState();
    this.checkFilterPanelState();
  }

  /* * *
  * setter nav bar Tab state
  * Param 1: should pass navbarconfig key ex : sideBar
  * Param 2: should pass tab index state like 0/1/2/3
  * * */
  onTabChanged(navBarkey: string, sideTabIndex) {
    this.checkFilterPanelState();
    this.navBarConfig[navBarkey] = sideTabIndex.index;
  }

  /*****
   * Side panel toggle based on menu state
   */
  checkPanelState() {
    if (this.selectedNav) {
      this.setNavBarPanelConfig('sidePanel', this.navBarConfig['sideBar']);
    }
  }

  /*****
   * Side panel toggle based on filter panel state
   */
  checkFilterPanelState() {
    if (this.navBarConfig['filterPanel']) {
      this.navBarConfig['filterPanel'] = !this.navBarConfig['filterPanel'];
    }
  }

  /* * *
  * method for get particular nav bar state
  * * */
  getNavBarPanelState(navBarkey: string) {
    return this.navBarConfig[navBarkey];
  }

  /* * *
  * method for set nav panel key for selected panel
  * Param 1: should pass menu : selected menu
  * Param 2: should pass navBarkey : side panel
  * * */
  setNavPanel(menu, navBarkey: string) {
    if (this.selectedNav === menu.id) {
      delete this.selectedNav;
      this.setNavBarPanelConfig(navBarkey, false);
    } else {
      this.selectedNav = menu.id;
      this.selectedNavMenu = menu;
      this.setNavBarPanelConfig(navBarkey, true);
    }
  }

  /* * *
  * method for get active nav panel 
  * * */
  getActiveNavPanel() {
    return this.selectedNav;
  }

  /* * *
  * method for get active nav panel 
  * * */
  getActiveNavPanelName() {
    return this.selectedNavMenu.name;
  }

  sidenav: MatSidenav;

  open() {
    this.isNotification = true;
    this.sidenav.open();
  }

  close() {
    this.isNotification = false;
    this.sidenav.close();
  }

  selectUserMode(mode) {
    this.selectedMenu = this.menuData[mode];
    this.navBarConfig['sidePanel'] = false;
    this.selectedNav = "";
  }

  /* * *
  * method for Toggle filter panel Show/Hide 
  * * */
  filterPanelToggle(navBarkey: string) {
    this.navBarConfig[navBarkey] = !this.navBarConfig[navBarkey];
  }

  /* * *
  * method for get panel show hide rule 
  * * */
  getPanelShowHideRule(panel) {
    return this.panelShowHideConfig[panel]['showhiderule'] || 'none';
  }

  /* * *
  * method for get active menu data 
  * * */
  getActiveNavMenu() {
    return this.selectedNavMenu;
  }

  /* * *
  * method for set navbar details
  * * */
  setNavbarDetails(navbarDetails) {
    this.navbarDetails = navbarDetails;
  }

  setCollectionList() {
    let collectionList = this.menuData.user.map(list => {
      if (list.category == 'annotation') {
        return list.id
      }
    })
    this.collectionList = collectionList.filter(list => list != undefined);
  }

  /* * *
  * method for get collections data
  * * */
  getCollectionList() {
    return this.collectionList;
  }

}
