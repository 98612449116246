import { Component, ViewContainerRef } from '@angular/core';
import { DbService } from '../../DB/db.service';
import { NavigationService } from '../../Service/navigation.service';
import { DataServiceService } from '../../Service/data-service.service';
import { PopupService } from './PopupMasters/popup.service';
import { NetworkConnectivityService } from '../../Service/network-connectivity.service';
@Component({
  selector: 'app-dfxmobileim',
  templateUrl: './dfxmobileim.component.html',
  styleUrls: ['./dfxmobileim.component.scss']
})

export class DfxmobileimComponent {


  constructor(public dataServ: DataServiceService, public navServ: NavigationService, private networkServ: NetworkConnectivityService, private dbService: DbService, public popupService: PopupService, public viewContainerRef: ViewContainerRef) {
    this.popupService.popupContainerRef = viewContainerRef;
  }

  ngOnInit() {
    this.getConfig();
  }

  /* * *
  * method for get prjectconfig
  * * */
  getConfig() {
    this.dataServ.getConfig().then((response: any) => {
      this.getProjectData();
    });
  }

  /**
   * method for get project data
   */
  getProjectData() {
    this.dbService.getData('ProjectData').then((result: any) => {
      this.navServ.showSyncScreen = result.length > 0 ? false : true;
    });
  }

  /**
   * method destroy internet service
   */
  ngOnDestroy(): void {
    this.networkServ.onDestroy();
  }

}
