import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Components 
import { DfxmobileimComponent } from './dfxmobileim.component';
import { LoaderComponent } from './loader/loader.component';
import { WorkSpaceComponent } from './work-space/work-space.component';
import { SystemNavComponent } from './system-nav/system-nav.component';
import { CollectionCategoryViewerComponent } from './system-nav/collection-category-viewer/collection-category-viewer.component';
import { SyncComponent } from './sync/sync.component';
import { DefectsComponent } from './work-space/defects/defects.component';
import { HeaderComponent } from './Header/header.component';
import { CameraComponent } from './work-space/camera/camera.component';

// Modules
import { DfximMaterialSharedModule } from 'src/app/Module/shared/dfxim-material-shared.module';

// service
import { NavigationService } from '../../Service/navigation.service';
import { WebcamModule } from 'ngx-webcam';


@NgModule({
  declarations: [
    DfxmobileimComponent,
    WorkSpaceComponent,
    SystemNavComponent,
    LoaderComponent,
    CollectionCategoryViewerComponent,
    SyncComponent,
    DefectsComponent,
    HeaderComponent,
    CameraComponent,
  ],
  imports: [
    DfximMaterialSharedModule,
    ReactiveFormsModule,
    FormsModule,
    WebcamModule,
  ],
  exports: [
    DfxmobileimComponent,
    WorkSpaceComponent,
    SystemNavComponent,
    LoaderComponent,
    CollectionCategoryViewerComponent,
    DefectsComponent,
    HeaderComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [NavigationService]
})

export class DfxmobileimModule { }
