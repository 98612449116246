import { Injectable } from '@angular/core';
import { BrodcastManagerService } from './brodcast-manager.service';

/**
 * Loader interface data
 * @param key : type of loader like "block, block-transparent, unblock"
 * @param message : loading meaage should pass
 */
export interface IloaderData {
  key: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})

export class LoaderService {
  broadcastSubs: any; // communication service instance
  loaderData: IloaderData = { key: "", message: "Loading..." }; //loader enable type like "block, block-transparent, unblock"

  constructor(private broadcastServ: BrodcastManagerService) {
    this.initBroadcastEvent();
  }

  /* * * * *
   * method for communicate event instance with data to access all components
   * * * * * */
  broadcastInfo(data: any) {
    this.broadcastServ.getInstance().next(data);
  }

  /* *
  * initialize broadcast event
  * * */
  initBroadcastEvent() {
    this.broadcastSubs = this.broadcastServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "loader") {

          //** Method for Block Loading enable/disable **//
          if (data.event === 'showBlockLoading') {
            if (data.state) {
              // Block transparent loader start section
              this.loaderData = { key: "block", message: data.message };
            } else {
              // Loader end section
              this.loaderData = { key: "", message: "" };
            }
          }

          //** Method for Non Block Loading enable/disable **//
          if (data.event === 'showNonBlockLoading') {
            if (data.state) {
              // Unblock Loader start section
              this.loaderData = { key: "unblock", message: data.percent + '% Loading...' };
            } else {
              // Loader end section
              this.loaderData = { key: "", message: data.percent + '% Loading...' };
            }
          }

          //** Method for syunc Loading enable/disable **//
          if (data.event === 'syncLoading') {
            if (data.state) {
              // Unblock Loader start section
              this.loaderData = { key: "sync", message: data.message };
            } else {
              // Loader end section
              this.loaderData = { key: "", message: '' };
            }
          }

        }
      })
  }


}
