/* *
*All the Header Settings in the Header Component
* * */
import { Component, HostListener, OnInit } from '@angular/core';
import { DataServiceService } from '../../../Service/data-service.service';
import { NavigationService } from '../../../Service/navigation.service';
import { ConfigService } from 'src/app/Service/config.service';
import { DbService } from '../../../DB/db.service';
import { BrodcastManagerService } from 'src/app/Service/brodcast-manager.service';
import { CollectionManagerService } from 'src/app/Service/collection-manager.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  profileDetails: any; // User Profile Data
  broadcastSubs: any; // Brodcast Subscribe
  navbarDetails: any; // Metaverse Details Data
  activeMetaverse: any; // active Metaverse Data
  notificationToggle: boolean = false;

  constructor(private dbService: DbService, private communicationServ: BrodcastManagerService, private configServ: ConfigService, public dataServ: DataServiceService, public navServ: NavigationService, private collectionServ: CollectionManagerService) { }

  ngOnInit(): void {


    this.getProfile();

    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "workSpace") {
          if (data.event === 'header') {
            var activeMetaverse = data.data
            this.activeMetaverse = activeMetaverse;
            this.getMetaverse();
          }
        }
        if (data.src === "annotation") {
          if (data.event === 'notification') {
            this.notificationToggle = data.data
          }
        }
      })

  }


  /* * *
  * get getInstance Brodcast Manager Service function
  * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  /* * *
  * get profile datas 
  * * */
  getProfile() {
    this.dbService.getData('ProfileData').then((profile_Json: any) => {
      this.profileDetails = [];
      this.profileDetails = profile_Json[0];
      // this.getLoggedData();
    })
  }

  /* * *
  * get Metaverse Datas 
  * * */
  getMetaverse() {
    this.configServ.getMetaverse().then((metaverse_Json: any) => {
      this.navbarDetails = metaverse_Json.find(t => t.metaverseName == this.activeMetaverse);
      this.navServ.showHeader = true;
      this.navServ.setNavbarDetails(this.navbarDetails);
      this.userMode();
    })
  }

  /* * *
  * Show Notification Data Panel 
  * * */
  notificationPanelShow() {
    // this.notificationToggle = !this.notificationToggle;
    // this.navServ.isNotification = true;
    // this.navServ.open();
    this.navServ.open();
    // this.broadcastInfo({ src: 'header', event: 'notification', data: this.notificationToggle });
  }

  /* * *
  * showing home screen
  * * */
  gotoHomeScreen() {  //** hiding header **/
    this.navServ.showHeader = false;

    //** configServ.renderVenue = false in the sence showing workspace screen **/
    this.navServ.renderVenue = false;

    //** method for reset all the stored collection data **/
    this.collectionServ.resetCollection();
  }

  /* * *
  * Method for switch project from header dropdown
  * * */
  renderProject(projectCode) {
    this.broadcastInfo({ src: 'header', event: 'onchange-project', project: projectCode });
  }

  /**
   * Select setting toggle event 
   * @param setting : selected setting data should pass 
   */
  selectSetting(setting) {
    if (setting.key == 'admin') {
      let settingsIdx = this.navbarDetails.settings.findIndex(e => e.key == 'admin');
      if (settingsIdx > -1) {
        this.navbarDetails.settings[settingsIdx]['active'] = !this.navbarDetails.settings[settingsIdx]['active'];
      }
      this.userMode();
    }
  }

  /**
  * User mode based on settings adimin mode toggle true/false
  */
  userMode() {
    if (this.navbarDetails) {
      let settingsIdx = this.navbarDetails.settings.findIndex(e => e.key == 'admin');
      if (settingsIdx > -1) {
        var selectedMode = this.navbarDetails.settings[settingsIdx]['active'] ? 'admin' : 'user';
      }
      this.navServ.selectUserMode(selectedMode);
    }
  }
  /* * *
  * method for logout the current session
  * * */
  logout() {
    // this.authServ.logoutPopup({
    //   mainWindowRedirectUri: "/"
    // });
    // delete this.navServ.loginData;
  }

  /**
   * Get logged data
   */
  getLoggedData() {
    if (!this.navServ.loginData) return;
    this.profileDetails['name'] = this.navServ.loginData['name'];
  }
}
