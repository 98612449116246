import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { DataServiceService } from './data-service.service';

@Injectable()

export class httpInterceptor implements HttpInterceptor {

  constructor(private dataServ: DataServiceService) { }

  auth = 'no'; // default authentication value
  projectCode = 'common'; // default project code value

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {

    let accessToken = this.dataServ.getAccessToken();
    let authentication = this.dataServ.project_config ? this.dataServ.project_config['authentication'] || this.auth : this.auth;
    let projectCode = this.dataServ.project_config ? this.dataServ.project_config['project'] || this.projectCode : this.projectCode;

    const newReq = req.clone({
      headers: req.headers
        .set('Authorization', "Bearer " + accessToken)
        .set('Authentication', authentication)
        .set('projectcode', projectCode)
    })

    return next.handle(newReq);

  }

}
