
/***
 * local DB configurations here
 */
export function dbConfig() {
    return {
        name: "devIMDB",
        version: 1,
        objectStoresMeta: [
            {
                store: 'ProjectData',
                storeConfig: { keyPath: 'rowNumber', autoIncrement: true },
                storeSchema: [
                    { name: 'projectCode', keypath: 'projectCode', options: { unique: false } },
                    { name: 'projectName', keypath: 'projectName', options: { unique: false } },
                    { name: 'thumbNailPath', keypath: 'thumbNailPath', options: { unique: false } },
                    { name: 'bookmark', keypath: 'bookmark', options: { unique: false } },
                    { name: 'status', keypath: 'status', options: { unique: false } },
                    { name: 'submenu', keypath: 'submenu', options: { unique: false } },
                    { name: 'secretStatusRequirementList', keypath: 'secretStatusRequirementList', options: { unique: false } },
                    { name: 'demoVideo', keypath: 'demoVideo', options: { unique: false } }
                ]
            },
            {
                store: 'ProfileData',
                storeConfig: { keyPath: 'rowNumber', autoIncrement: true },
                storeSchema: [
                    { name: 'avatar', keypath: 'avatar', options: { unique: false } },
                    { name: 'name', keypath: 'name', options: { unique: false } },
                    { name: 'role', keypath: 'role', options: { unique: false } },
                    { name: 'profileAction', keypath: 'profileAction', options: { unique: false } }
                ]
            },
            {
                store: 'collectionType',
                storeConfig: { keyPath: 'rowNumber', autoIncrement: true },
                storeSchema: [
                    { name: 'id', keypath: 'id', options: { unique: false } },
                    { name: 'name', keypath: 'name', options: { unique: false } },
                    { name: 'icon', keypath: 'icon', options: { unique: false } },
                    { name: 'description', keypath: 'description', options: { unique: false } },
                    { name: 'status', keypath: 'status', options: { unique: false } },
                    { name: 'venue', keypath: 'venue', options: { unique: false } },
                    { name: 'category', keypath: 'category', options: { unique: false } }
                ]
            },
            {
                store: 'collectionNotes',
                storeConfig: { keyPath: 'rowNumber', autoIncrement: true },
                storeSchema: [
                    { name: 'annotationId', keypath: 'annotationId', options: { unique: false } },
                    { name: 'annotationName', keypath: 'annotationName', options: { unique: false } },
                    { name: 'collectionRefId', keypath: 'collectionRefId', options: { unique: false } },
                    { name: 'description', keypath: 'description', options: { unique: false } },
                    { name: 'endDate', keypath: 'endDate', options: { unique: false } },
                    { name: 'endTime', keypath: 'endTime', options: { unique: false } },
                    { name: 'formStatus', keypath: 'formStatus', options: { unique: false } },
                    { name: 'group', keypath: 'group', options: { unique: false } },
                    { name: 'marker', keypath: 'marker', options: { unique: false } },
                    { name: 'markerId', keypath: 'markerId', options: { unique: false } },
                    { name: 'parentMeshId', keypath: 'parentMeshId', options: { unique: false } },
                    { name: 'pmrsNo', keypath: 'pmrsNo', options: { unique: false } },
                    { name: 'props', keypath: 'props', options: { unique: false } },
                    { name: 'shapeId', keypath: 'shapeId', options: { unique: false } },
                    { name: 'startDate', keypath: 'startDate', options: { unique: false } },
                    { name: 'startTime', keypath: 'startTime', options: { unique: false } },
                    { name: 'status', keypath: 'status', options: { unique: false } },
                    { name: 'tagName', keypath: 'tagName', options: { unique: false } },
                    { name: 'tagPoints', keypath: 'tagPoints', options: { unique: false } },
                    { name: 'tagShapeId', keypath: 'tagShapeId', options: { unique: false } },
                    { name: 'typeCollection', keypath: 'typeCollection', options: { unique: false } },
                    { name: 'typeCollectionId', keypath: 'typeCollectionId', options: { unique: false } },
                    { name: 'uploadFile', keypath: 'uploadFile', options: { unique: false } },
                    { name: 'userList', keypath: 'userList', options: { unique: false } },
                    { name: '_id', keypath: '_id', options: { unique: false } },
                    { name: 'defectsData', keypath: 'defectsData', options: { unique: false } },
                    { name: 'venue', keypath: 'venue', options: { unique: false } },
                    { name: 'localDB', keypath: 'localDB', options: { unique: false } },
                ]
            },
            {
                store: 'collectionInfo',
                storeConfig: { keyPath: 'rowNumber', autoIncrement: true },
                storeSchema: [
                    { name: 'annotationName', keypath: 'annotationName', options: { unique: false } },
                    { name: 'annotationId', keypath: 'annotationId', options: { unique: false } },
                    { name: 'annotationType', keypath: 'annotationType', options: { unique: false } },
                    { name: 'groupCollection', keypath: 'groupCollection', options: { unique: false } },
                    { name: 'shapeId', keypath: 'shapeId', options: { unique: false } },
                    { name: 'statusCollection', keypath: 'statusCollection', options: { unique: false } },
                    { name: 'venue', keypath: 'venue', options: { unique: false } },
                ]
            }
        ]
    };
}
