import { Component } from '@angular/core';
import { CollectionManagerService } from 'src/app/Service/collection-manager.service';
import { NavigationService } from 'src/app/Service/navigation.service';
import { LoaderService } from 'src/app/Service/loader.service';
import { DataServiceService } from '../../../Service/data-service.service';
import { DbService } from '../../../DB/db.service';
import { BrodcastManagerService } from 'src/app/Service/brodcast-manager.service';

@Component({
  selector: 'app-work-space',
  templateUrl: './work-space.component.html',
  styleUrls: ['./work-space.component.scss']
})
export class WorkSpaceComponent {
  projectDataStruct = []; //store all project datas
  selectedProject: any; //store selected project code 

  constructor(private broadcastServ: BrodcastManagerService, private dbService: DbService, private loaderServ: LoaderService, public dataServ: DataServiceService, private collectionServ: CollectionManagerService, public navServ: NavigationService,) { }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.broadcastServ.getInstance().next(data);
  }

  ngOnInit(): void {
    this.getprojectDataStruct();
  }

  /* * *
  * get project datas 
  * * */
  getprojectDataStruct() {
    // Block transparent loader start section 
    this.dbService.getData('ProjectData').then((project_Json: any) => {
      this.projectDataStruct = project_Json;
    }).catch((e) => {
      // exception error
      console.error("getProjectJson menthod have error", e);
    })
  }

  /* * *
  * method for render venue and load project based on project code
  * * */
  openProject(event, project) {
    //** menu icon triggering menu **/
    if (event.srcElement.id == "subMenu") {
      return
    }

    //** code for triggers load venue action **/
    if (project) {
      this.dataServ.setProjectConfig(project.projectCode).then(response => {
        //** assign header show true **/       
        this.fetchCollectionCategory(project.projectCode);
        this.navServ.renderVenue = true;
        this.dataServ.selectedProjectData = project;
        this.selectedProject = project.projectCode;
        this.dataServ.projectName = this.selectedProject;
        //  this.broadcastInfo({ src: 'workSpace', event: 'header', data: this.selectedProject });
      });
    }
  }

  /* * *
  * method for fetch collection list
  * * */
  fetchCollectionCategory(project) {
    this.dbService.getCollectionCategory(project).then(result => {
      this.collectionServ.setCollectionCategoryList(result);
      let selectedMode = "user";
      this.navServ.selectUserMode(selectedMode);
      this.navServ.renderVenue = true;
      this.broadcastInfo({ src: 'work-space', event: 'open-default-nav-menu', data: '' });
    })
  }


}
